/* eslint-disable react/prop-types */
import styles from './styles.module.css'

import { useState, useEffect } from 'react'

import SimpleEditionModal from '../../../components/SimpleEditionModalTemplate'
import PropTypes from 'prop-types'

// import { Loading } from '../../../components/Loading'

import lazyLoadingAnimation from '../../../assets/lottie/lazy-loading-animation.json'
import Lottie from 'lottie-react'
import { isEmpty } from '../../../utils/verifyObject'

import {
  BrandingService,
  BusinessService,
  EditorialLineService,
  FreepikService,
  LogosService,
  // ProductService,
  PythonService,
  TargetAudienceService,
  VoiceToneService,
} from '../../../services/'
import { ProductFruits } from 'react-product-fruits'

const STEPS = {
  STEP_SECOND_PASS_IMAGE: 'stepImage',
  STEP_FOURTH_PASS_SUBTITLE: 'stepSubtitles',
}

function hexToRgb(hex) {
  hex = hex.replace(/^#/, '')

  if (/^[0-9A-Fa-f]{6}$|^[0-9A-Fa-f]{3}$/.test(hex)) {
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map(function (char) {
          return char + char
        })
        .join('')
    }

    const r = parseInt(hex.slice(0, 2), 16)
    const g = parseInt(hex.slice(2, 4), 16)
    const b = parseInt(hex.slice(4, 6), 16)

    return `(${r},${g},${b})`
  } else {
    return `(${0},${0},${0})`
  }
}

function StepThirdPass({
  nincheData,
  setStep,
  iaData,
  setIaData,
  qtdLoadTemplates,
  setQtdLoadTemplates,
  qtdTemplatesLimit,
  clientData,
  setImageUrl,
  imageUrl,
  imageLayers,
  setImageLayers,
  headlineText,
  setHeadlineText,
  imageGaleryArray,
  imageArray,
  imageAIArray,
  imageUploadArray,
  imageProductsArray,
  setHeadlineList,
  setLegendList,
  productRef,
  targetAudienceRef,
  templateId = 'random',
  funelOfSaleRef,
  selectFunnel,
  setHeadlineGpt,
  headlineGpt,
  publicationDescriptionRef,
}) {
  const [isModalOpen, setModalOpen] = useState(false)
  // const [loading, setLoading] = useState(false)

  const [headlineSubtitle, setHeadlineSubtitle] = useState('')
  const [headlineGptLocal, setHeadlineGptLocal] = useState(null)
  const [imageFreepik, setImageFreepik] = useState(null)
  const [hasSignature, setHasSignature] = useState(false)

  useEffect(() => {
    if (qtdLoadTemplates <= qtdTemplatesLimit) {
      // if (qtdLoadTemplates <= 0) {

      // if (qtdLoadTemplates <= 0) {

      fetchData()
    }
  }, [qtdLoadTemplates, headlineGptLocal])

  const fetchData = async () => {
    // setLoading(true)

    const loadbusiness = await loadBusinessData()

    if (!headlineGptLocal) {
      if (imageArray?.length !== 0 && imageArray) {
        await getDownloadFreepik(imageArray)
      }
      await getIaHeadline(loadbusiness)
    } else {
      await getIaImages(loadbusiness)
    }

    // setLoading(false)
  }

  const getDownloadFreepik = async (imageArray) => {
    const imgBase64 = []
    imageArray.forEach(async (image) => {
      if (image.idFreepik) {
        setHasSignature(true)
        const imageDownloaded = await FreepikService.getFreepikDownload(
          image.idFreepik
        )
        imgBase64.push({
          base64: imageDownloaded.data,
          imageCategory: image.categoryId,
          idFreepik: image.idFreepik,
        })
      }
    })
    setImageFreepik(imgBase64)
  }

  const loadBusinessData = async () => {
    let obj = {}

    if (clientData?.uuid) {
      let businessByClientReturn = await BusinessService.getBusinessByClientId(
        clientData?.uuid
      )

      let businessByClientReturnId = businessByClientReturn?.data[0].uuid

      const brandingInfos = await BrandingService.getAllBrandingByBusinessId(
        businessByClientReturnId
      )
      const voiceToneInfos = await VoiceToneService.getAllVoiceToneByBusinessId(
        businessByClientReturnId
      )
      const businessInfos = businessByClientReturn

      const targetAudiencesInfos =
        await TargetAudienceService.getAllTargetAudienceByClientId(
          businessByClientReturnId
        )

      obj = {
        brandingInfos: brandingInfos,
        voiceToneInfos: voiceToneInfos,
        businessInfos: businessInfos,
        targetAudiencesInfos: targetAudiencesInfos,
      }
    } else {
      const brandingInfos = await BrandingService.getAllBrandingByBusiness()
      const voiceToneInfos = await VoiceToneService.getAllVoiceToneByBusiness()
      const businessInfos = await BusinessService.getBusinessByLogin()
      const targetAudiencesInfos =
        await TargetAudienceService.getAllTargetAudience()

      obj = {
        brandingInfos: brandingInfos,
        voiceToneInfos: voiceToneInfos,
        businessInfos: businessInfos,
        targetAudiencesInfos: targetAudiencesInfos,
      }
    }

    // setResponseBusinesData(obj)

    return obj
  }

  const numImage = 4
  const headlineListLocal = []
  const legendListLocal = []
  const textHeadline = []

  const getIaHeadline = async (loadbusiness) => {
    let businessData = loadbusiness

    // const totalMainImages =
    //   imageGaleryArray?.length +
    //   imageArray?.length +
    //   imageAIArray?.length +
    //   imageUploadArray?.length +
    //   imageProductsArray?.length
    // const numHeadline = numImage * totalMainImages

    if (targetAudienceRef.current) {
      if (targetAudienceRef.current.label == 'Nenhum') {
        targetAudienceRef.current = undefined
      }
    }

    if (productRef.current) {
      if (productRef.current.label == 'Nenhum') {
        productRef.current = undefined
      }
    }
    let getEditorialLine
    let editorialLine

    if (clientData?.uuid) {
      getEditorialLine = await EditorialLineService.getEditorialLineByClient(
        clientData?.uuid
      )
      editorialLine = getEditorialLine.data[0].bodyJson
    } else {
      getEditorialLine = await EditorialLineService.getEditorialLinebyLogin()
      editorialLine = getEditorialLine?.data[0]?.bodyJson
    }

    let editorialBody

    editorialBody = {
      flow: 'unique_publication',
      prompt: 'headline',
      post_description: publicationDescriptionRef.current, //editorialLine?.dados_da_marca?.descricao_negocio,
      brand: editorialLine?.dados_da_marca.nicho,
      funil: selectFunnel,
      editorial_line: {
        primary: {
          titulo: editorialLine?.linha_conteudo_principal.titulo,
          descricao: editorialLine?.linha_conteudo_principal.descricao,
        },
        secondary: [
          {
            titulo: editorialLine?.linhas_conteudo_secundarias[1].titulo,
            descricao: editorialLine?.linhas_conteudo_secundarias[1].descricao,
            exemplos_conteudo:
              editorialLine?.linhas_conteudo_secundarias[1].exemplos_conteudo,
          },
          {
            titulo: editorialLine?.linhas_conteudo_secundarias[2].titulo,
            descricao: editorialLine?.linhas_conteudo_secundarias[2].descricao,
            exemplos_conteudo:
              editorialLine?.linhas_conteudo_secundarias[1].exemplos_conteudo,
          },
          {
            titulo: editorialLine?.linhas_conteudo_secundarias[3].titulo,
            descricao: editorialLine?.linhas_conteudo_secundarias[3].descricao,
            exemplos_conteudo:
              editorialLine?.linhas_conteudo_secundarias[1].exemplos_conteudo,
          },
          {
            titulo: editorialLine?.linhas_conteudo_secundarias[4].titulo,
            descricao: editorialLine?.linhas_conteudo_secundarias[4].descricao,
            exemplos_conteudo:
              editorialLine?.linhas_conteudo_secundarias[1].exemplos_conteudo,
          },
        ],
      },
      niche: editorialLine?.dados_da_marca.nicho,
      subniche: editorialLine?.dados_da_marca.subnicho,
      business_description: editorialLine?.dados_da_marca.descricao_negocio,
      target_public: {
        name: targetAudienceRef.current.name || '',
        gender: targetAudienceRef.current.genderValue || '',
        social_class: targetAudienceRef.current.socialClassValue.join(', '),
        profession: targetAudienceRef.current.profession || '',
        region: 'Nacional, com foco em grandes centros urbanos',
      },
      product: {
        name: productRef.current?.name || '',
        type: productRef.current?.productTypeValue || '',
        description: productRef.current?.description || '',
        value: productRef.current?.price || '',
        promotional_value: productRef.current?.promotionalValue || '',
      },

      voice_tone: editorialLine?.tom_de_voz,
      voice_tone_description: editorialLine?.tipo_post,
    }

    let retEditorialLine = await PythonService.postPromptGPT(editorialBody)
    // setTimeout(() => {
    //   setHeadlineGpt(retEditorialLine)
    //   setHeadlineGptLocal(retEditorialLine)
    // }, 2000); // 2 segundos de delay
    setHeadlineGpt(retEditorialLine)
    setHeadlineGptLocal(retEditorialLine)

    // let bodyHeadline = {
    //   publications_number: numHeadline,
    //   niche: businessData.businessInfos?.data[0].BusinessNiche?.name,
    //   subniche: businessData.businessInfos?.data[0].subNiche,
    //   publication_purpose: 'divulgar',
    //   description_business: businessData.businessInfos?.data[0].description,
    //   voice_tone: businessData.voiceToneInfos?.data[0].tone,

    //   social_class: targetAudienceRef.current
    //     ? targetAudienceRef.current.socialClassValue
    //     : '',

    //   gender: targetAudienceRef.current
    //     ? targetAudienceRef.current?.genderValue
    //     : '',

    //   profession: targetAudienceRef.current
    //     ? targetAudienceRef.current?.profession
    //     : '',

    //   name_product: productRef.current ? productRef.current?.name : '',

    //   product_type: productRef.current
    //     ? productRef.current?.productTypeValue
    //     : '',

    //   product_description: productRef.current
    //     ? productRef.current?.description
    //     : '',

    //   value: productRef.current ? productRef.current?.price : '',

    //   promotional_value: productRef.current
    //     ? productRef.current?.promotionalValue
    //     : '',
    // }

    // const headline = await PythonService.postHeadline(bodyHeadline)

    // await headline?.data?.data.forEach((element) => {
    //   textHeadline.push(element)
    //   headlineListLocal.push(element.titulo)
    //   legendListLocal.push(element.subtitulo)
    // })

    // await setHeadlineList(headlineListLocal)
    // await setLegendList(legendListLocal)

    // await setHeadlineText(headline?.data.data[0].titulo)
  }

  const getIaImages = async (loadbusiness) => {
    let retEditorialLine = null
    let retEditorialLineMenu = null
    let getEditorialLine
    let editorialLine
    let editorialBodyId

    if (!isEmpty(nincheData)) {
      if (clientData?.uuid) {
        getEditorialLine = await EditorialLineService.getEditorialLineByClient(
          clientData?.uuid
        )
        editorialLine = getEditorialLine.data[0].bodyJson
      } else {
        getEditorialLine = await EditorialLineService.getEditorialLinebyLogin()
        editorialLine = getEditorialLine?.data[0]?.bodyJson
      }

      editorialBodyId = {
        flow: 'template_publication',
        prompt: 'headline',
        post_description: publicationDescriptionRef.current, //editorialLine?.dados_da_marca?.descricao_negocio,
        brand: editorialLine?.dados_da_marca.nicho,
        funil: selectFunnel,
        template_id:
          nincheData.templateId.replace('.json', '') ?? nincheData.templateId,
        niche: editorialLine?.dados_da_marca.nicho,
        subniche: editorialLine?.dados_da_marca.subnicho,
        product: {
          name: productRef.current?.name || '',
          type: productRef.current?.productTypeValue || '',
          description: productRef.current?.description || '',
          value: productRef.current?.price || '',
          promotional_value: productRef.current?.promotionalValue || '',
        },
        target_public: {
          name: targetAudienceRef.current.name || '',
          gender: targetAudienceRef.current.genderValue || '',
          social_class: targetAudienceRef.current.socialClassValue.join(', '),
          profession: targetAudienceRef.current.profession || '',
          region: 'Nacional, com foco em grandes centros urbanos',
        },
        voice_tone: editorialLine?.tom_de_voz,
      }
      retEditorialLineMenu = await PythonService.postPromptGPT(editorialBodyId)
    }

    let businessData = loadbusiness
    const imgBase64 = []
    const logoBase64 = []

    const findFont1 =
      businessData.brandingInfos?.data[0]?.fontsBase64?.fontTitleBase64?.data ||
      ''
    const findFont2 =
      businessData.brandingInfos?.data[0]?.fontsBase64?.fontSubtitleBase64
        ?.data || ''
    const findFont3 =
      businessData.brandingInfos?.data[0]?.fontsBase64?.fontTextBase64?.data ||
      ''

    if (clientData?.uuid) {
      const clientLogo = await LogosService.getLogosByClientBase64(
        clientData?.uuid
      )
      //const campaignLogo = await LogosService.getLogosByCampaignBase64(campaignId)
      if (clientLogo?.length !== 0 && clientLogo) {
        clientLogo.data.forEach((logo) => {
          logoBase64.push({ base64: logo.fileBase64 })
        })
      }
    } else {
      const userLogo = await LogosService.getLogosUserBase64()

      if (userLogo?.length !== 0 && userLogo) {
        userLogo.data.forEach((logo) => {
          logoBase64.push({ base64: logo.fileBase64 })
        })
      }
    }

    // const templates = [
    //   'ID14.json',
    //   'ID62.json',
    //   'ID43.json',
    //   'ID59.json',
    //   'ID33.json',
    //   'ID65.json',
    //   'ID45.json',
    // ]

    const templates = [headlineGptLocal?.data?.data?.headlines[0]?.id + '.json']

    let bodyGenerateArt = {
      // selected_templates: ['ID45.json'],
      // selected_templates: templateId
      //   ? [templateId]
      //   : [templates[qtdLoadTemplates]],

      //selected_templates: [templates[qtdLoadTemplates]],
      // selected_templates: [templates[qtdLoadTemplates]],
      selected_templates: [
        nincheData.templateId ??
          headlineGptLocal?.data?.data?.headlines[qtdLoadTemplates]?.id +
            '.json',
      ],

      // publications_number: 6, //! Padrao

      publications_number: 1, //! Padrao
      template: false,
      art_width: 1080,
      art_height: 1080,
      color_palette_1: hexToRgb(
        businessData.brandingInfos?.data[0]?.primaryColor
      ),
      color_palette_2: hexToRgb(
        businessData.brandingInfos?.data[0]?.secondaryColor
      ),
      color_palette_3: hexToRgb(
        businessData.brandingInfos?.data[0]?.tertiaryColor
      ),
      color_palette_4: '(0,0,0)', //! Padrao
      color_palette_5: '(255,255,255)', //! Padrao
      // text: [[headlineListLocal[0]]],
      text: retEditorialLineMenu
        ? retEditorialLineMenu.data.data
        : headlineGptLocal?.data?.data?.headlines[qtdLoadTemplates],
      // categoria: [['titulo']],
      niche: businessData.businessInfos?.data[0].BusinessNiche?.name || '',
      subniche: businessData.businessInfos?.data[0].subNiche || '',
      escolaridade: targetAudienceRef.current?.educationLevelValue || '',
      publication_purpose: 'divulgar', //! Padrao
      description_business:
        businessData.businessInfos?.data[0].description || '',
      voice_tone: businessData.voiceToneInfos?.data[0].tone || '',
      social_class: targetAudienceRef.current
        ? targetAudienceRef.current.socialClassValue
        : '',
      gender: targetAudienceRef.current?.genderValue || '',
      profession: targetAudienceRef.current?.profession || '',
      name_product: productRef.current?.name || '',
      product_type: productRef.current?.productTypeValue || '',
      product_description: productRef.current?.description || '',
      value: productRef.current?.price || '',
      promotional_value: productRef.current?.promotionalValue || '',
      produto: productRef.current?.name || '',

      complexidade: -3,
      masculino: 5,
      extravagante: -5,
      moderno: 5,
      distante: 0,
      serio: 0,
      industrial: 0,
      elitizado: 0,
      sofisticado: 0,
      refinado: 0,

      macro_effects: false,

      num_imgs: [1],

      has_logo: logoBase64.length > 0 ? true : false,

      // fonte_1: 'montserrat',
      // fonte_2: 'roboto',
      // fonte_3: 'protestriot',

      fonte_1: findFont1 || 'montserrat',
      fonte_2: findFont2 || 'roboto',
      fonte_3: findFont3 || 'protestriot',

      // background_img_base64: null,
      hasSignature: hasSignature,
      logo_base64: logoBase64,
    }

    let sequenceId = 1

    if (imageArray?.length !== 0 && imageArray) {
      if (!imageFreepik) {
        imageArray.forEach((image2) => {
          imgBase64.push({
            base64: image2.base64,
            imageCategory: image2.imageCategory,
            id: sequenceId,
            fileFormat: 'image/jpeg',
          })
          sequenceId++
        })
      } else {
        imageFreepik.forEach(async (image) => {
          imgBase64.push({
            base64: image.base64,
            imageCategory: image.imageCategory,
            id: sequenceId,
          })
          sequenceId++
        })
      }
    }

    if (imageGaleryArray?.length !== 0 && imageGaleryArray) {
      imageGaleryArray.forEach((image2) => {
        imgBase64.push({
          base64: image2.fileDataBase64.split(',')[1],
          imageCategory: image2.categoryId,
          id: sequenceId,
          fileFormat: image2.type,
        })
        sequenceId++
      })
    }

    if (imageAIArray.length !== 0 && imageAIArray) {
      imageAIArray.forEach((image2) => {
        const match = image2.image.match(/^data:(.*?);base64,/)

        imgBase64.push({
          base64: image2.image.split(',')[1],
          imageCategory: image2.categoryId,
          id: sequenceId,
          fileFormat: match ? match[1] : null,
        })
        sequenceId++
      })
    }

    if (imageUploadArray.length !== 0 && imageUploadArray) {
      imageUploadArray.forEach((image2) => {
        imgBase64.push({
          base64: image2.image.split(',')[1],
          imageCategory: image2.categoryId,
          id: sequenceId,
          fileFormat: image2.type,
        })
        sequenceId++
      })
    }

    if (imageProductsArray.length !== 0 && imageProductsArray) {
      imageProductsArray.forEach((image2) => {
        imgBase64.push({
          base64: image2.fileDataBase64.split(',')[1],
          imageCategory: image2.categoryId,
          productId: image2.productId,
          productName: image2.productName,
          productDescription: image2.productDescription,
          productValue: image2.productValue,
          productPromotionValue: image2.productPromotionValue,
          fileFormat: image2.type,
        })
      })
    }

    if (nincheData && nincheData.VariantSelected) {
      bodyGenerateArt.specificTemplate = nincheData.VariantSelected
    }

    bodyGenerateArt = {
      ...bodyGenerateArt,
      img_base64: imgBase64,
    }

    const generateArtReturn = await PythonService.postGenerateArtTemplate(
      bodyGenerateArt
    )

    // if (generateArtReturn?.data?.status === 200) {
    // setIaData(generateArtReturn?.data?.data)

    // setItems(
    //   items.map((item, index) => {
    //     if (index === qtdLoadTemplates) {
    //       return {
    //         ...generateArtReturn?.data?.data[0],
    //       }
    //     }
    //     return item
    //   })
    // )
    // setQtdLoadTemplates(qtdLoadTemplates + 1)

    setIaData(
      iaData.map((item, index) => {
        if (index === qtdLoadTemplates) {
          return {
            ...generateArtReturn?.data?.data[0],
          }
        }
        return item
      })
    )
    setQtdLoadTemplates(qtdLoadTemplates + 1)
  }

  // setLoading(false)
  // }

  const handleOpenModal = (url, imageLayers, item) => {
    setImageUrl(url)
    setImageLayers(imageLayers)

    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const HeaderStepRender = () => {
    return (
      <>
        <div
          className={styles.title_new_posts}
          style={{
            marginTop: 0,
            marginBottom: 10,
          }}
        >
          <p className={styles.title_new_posts_label}>Selecione uma</p>
          <p className={styles.title_new_posts_title}>Sugestão</p>
        </div>
      </>
    )
  }

  const ContentField = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',

          paddingBottom: '0px',
          marginRight: '22px',
          height: 'calc(100vh - 200px)',
          overflowY: 'auto',
        }}
      >
        <div className={styles.container_content}>
          <div className={styles.container_examples}>
            <div className={styles.container_examples_image}>
              {iaData?.map((item, index) => (
                <>
                  {item?.srcComTratativa && (
                    <div
                      className={styles.box_example_image}
                      key={index}
                      onClick={() => {
                        handleOpenModal(
                          'data:image/png;base64,' + item?.srcComTratativa,
                          item,
                          item
                        )
                      }}
                    >
                      <img
                        src={'data:image/png;base64,' + item?.srcComTratativa}
                        alt=""
                        className={styles.example_image}
                      />
                    </div>
                  )}

                  {!item?.srcComTratativa && (
                    <div className={styles.box_example_image} key={index}>
                      <Lottie
                        animationData={lazyLoadingAnimation}
                        style={{ width: 100, height: 100 }}
                        loop={true}
                        autoplay={true}
                      />
                    </div>
                  )}
                </>
              ))}

              <SimpleEditionModal
                isOpen={isModalOpen}
                setNextStep={() => setStep(STEPS.STEP_FOURTH_PASS_SUBTITLE)}
                imageUrl={imageUrl}
                setImageUrl={setImageUrl}
                imageLayers={imageLayers}
                setImageLayers={setImageLayers}
                onClose={handleCloseModal}
                title={headlineText}
                subtitle={headlineSubtitle}
                publicTargetOptions={[]}
                productOptions={[]}
                onChangeColorPalleteSubtittle={(e) =>
                  setHeadlineSubtitle(e.target.value)
                }
                onChangeColorPalleteTittle={(e) =>
                  setHeadlineText(e.target.value)
                }
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const FooterStepRender = () => {
    return (
      <div
        style={{
          marginRight: '22px',

          background: '#fff',
          paddingTop: '10px',
        }}
      >
        <div className={styles.button_nav}>
          <button
            className={styles.button_back}
            onClick={() => {
              setStep('stepImage')
            }}
          >
            <p className={styles.button_title}>Voltar</p>
          </button>
        </div>
      </div>
    )
  }
  const storage = JSON.parse(window.localStorage.getItem('userData')).userEmail
  const userInfo = {
    username: storage, // REQUIRED, must be unique
  }
  return (
    <>
      {/* <Loading enable={loading} /> */}
      <div className={styles.container_header}>
        <ProductFruits
          workspaceCode="FzMKHdjfiHGoWeMW"
          language="en"
          user={userInfo}
        />
        {HeaderStepRender()}
        {ContentField()}
        {FooterStepRender()}
      </div>
    </>
  )
}

export default StepThirdPass

StepThirdPass.propTypes = {
  nincheData: PropTypes.object,
  setStep: PropTypes.func,
  imageArray: PropTypes.array,
  setIaData: PropTypes.func,
  iaData: PropTypes.object,
}
