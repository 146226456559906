/* eslint-disable react/prop-types */
import styles from './styles.module.css';

import ReactPlayer from 'react-player';
import mediaInfoFactory from 'mediainfo.js';
import SchedulerCalendar from '../../../components/SchedulerCalendar';
import CarouselHours from '../../../components/CarouselHours';

import { useNavigate } from 'react-router-dom';

import {
  notifyErrorCustom,
  notifySuccessCustom,
  notifyWarningCustom,
} from '../../../utils/notify';
import { useClient } from '../../../hooks/useClient';
import { getBusinessByClientId } from '../../../services/business';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import {
  BusinessService,
  PublicationService,
  TiktokService,
  // HeadlineHasPublicationService,
  VoiceToneService,
} from '../../../services';

import {
  Picture,
  Instagram,
  Linkedin,
  Pinterest,
  TikTok,
  Twitter,
  Calendar2,
  Subtract,
  PinMapsIcon,
  MorePersonsIcon,
  LinksIcon,
  HashtagIcon,
  FacebookChatIcon,
  Cross,
  Clips,
  MovieIcon,
  LockClosed,
  TikTokIcon,
} from '../../../assets/svgs/icons';

import { useState, useEffect, useRef } from 'react';

import { ModalComponent } from '../../../components/ModalComponent';
import { ImageRender } from '../../../components/ImageRender';
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare';

import { Loading } from '../../../components/Loading';

import { postPromptGPT } from '../../../services/pythonService';
import { ProductFruits } from 'react-product-fruits';

dayjs.extend(utc);

function readChunk(file) {
  return async (chunkSize, offset) =>
    new Uint8Array(await file.slice(offset, offset + chunkSize).arrayBuffer());
}

function StepMyPublication({
  setStep,
  publicationIdRef,
  imageUrl,
  setImageUrl,
  // clientData,
}) {
  const [videoUrl, setVideoUrl] = useState('');
  const [posterUrl, setPosterUrl] = useState('');
  const [isTiktokValidVideo, setIsTiktokValidVideo] = useState(true);

  const { clientData } = useClient();
  const navigate = useNavigate();

  const [oppenedModalLocale, setOppenedModalLocale] = useState(false);
  const [oppenedModalPersons, setOppenedModalPersons] = useState(false);

  const [oppennedModalLinks, setOppennedModalLinks] = useState(false);
  const [oppennedModalHashtags, setOppennedModalHashtags] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date().toDateString());
  const [valueHour, setValueHour] = useState('');
  const [valueMinute, setValueMinute] = useState('');

  const [selectedValues, setSelectedValues] = useState({});
  const selectedValuesString = Object.keys(selectedValues).toString() || '';

  const [publicationLegend, setPublicationLegend] = useState('');

  const [gptInput, setGptInput] = useState('');

  const [loading, setLoading] = useState(false);

  const [valueItem, setValueItem] = useState(1);

  const [tiktokCreatorInfo, setTiktokCreatorInfo] = useState(false);
  const [tiktokMusic, setTiktokMusic] = useState(false);
  const [dropDownTiktok, setDropDownTiktok] = useState(false);
  const [tiktokPrivacyLevel, setTiktokPrivacyLevel] = useState(false);
  const [tiktokComment, setTiktokComment] = useState(false);
  const [tiktokDuet, setTiktokDuet] = useState(false);
  const [tiktokStitch, setTiktokStitch] = useState(false);
  const [tiktokContentDisclosure, setTiktokContentDisclosure] = useState(false);
  const [tiktokYourBrand, setTiktokYourBrand] = useState(false);
  const [tiktokBrandedContent, setTiktokBrandedContent] = useState(false);

  const mediaInfoRef = useRef();

  const [tiktokBrandedInfo, setTiktokBrandedInfo] = useState(
    'Você precisa indicar se seu conteúdo promove você mesmo, um terceiro ou ambos.'
  );

  const [publicationMovieType, setPublicationMovieType] = useState(undefined);

  useEffect(() => {
    mediaInfoFactory({ format: 'object' })
      .then((mediaInfo) => {
        mediaInfoRef.current = mediaInfo;
      })
      .catch((error) => {
        console.error('Error loading mediaInfo:', error);
      });

    return () => {
      if (mediaInfoRef.current) {
        mediaInfoRef.current.close();
      }
    };
  }, []);

  const returnUploadImageBucket = async (data) => {
    function extractImageData(base64String) {
      // Dividir a string base64 para separar o cabeçalho da imagem
      const parts = base64String.split(',');
      const header = parts[0];
      const base64Data = parts[1];

      // Extrair o formato do arquivo e o tipo de conteúdo
      const matches = header.match(/^data:([A-Za-z-+\/]+);base64$/);
      const fileContentType = matches[1];
      const fileFormat = fileContentType.split('/')[1];

      // Retornar os dados no formato desejado
      return {
        fileName: 'publication',
        fileFormat: fileFormat,
        fileContentType: fileContentType,
        fileBase64: base64Data,
      };
    }

    let body = extractImageData(data);

    const publicationImageBucket =
      await PublicationService.postPublicationImage(body, selectedValuesString);

    if (publicationImageBucket?.responseStatus === 200) {
      return publicationImageBucket?.data;
    } else {
      console.log(publicationImageBucket?.responseStatus);
    }
  };

  const handleProgress = (state) => {
    if (selectedValues[3]) {
      if (state.loadedSeconds > tiktokCreatorInfo.max_video_post_duration_sec) {
        notifyErrorCustom(
          `Erro ao carregar vídeo, você deve utilizar videos de até ${tiktokCreatorInfo.max_video_post_duration_sec} segundos`
        );
        setVideoUrl(false);

        return;
      }
    }
  };

  const handleClick = async (value) => {
    // Verifica se o valor já está no array

    if (value === 3 && !tiktokCreatorInfo) {
      const tiktokCreatorInfoData = await TiktokService.getTiktokCreatorInfo(
        clientData.businessId
      );

      if (tiktokCreatorInfoData.dataT.error.code !== 'ok') {
        notifyErrorCustom(
          'Erro ao carregar as permissões do usuário. Por favor, verifique seus dados no Tiktok ou tente novamente mais tarde.'
        );
        return;
      }

      setTiktokCreatorInfo(tiktokCreatorInfoData.dataT.data);

      setDropDownTiktok(
        tiktokCreatorInfoData.dataT.data.privacy_level_options.map((item) => ({
          label: item,
          value: item,
        }))
      );
    }
    if (selectedValues[value]) {
      // Se já estiver no objeto, remove o valor
      const newValues = { ...selectedValues };
      delete newValues[value];
      setSelectedValues(newValues);
    } else {
      // Se não estiver no objeto, adiciona o valor
      setSelectedValues({ ...selectedValues, [value]: true });
    }
  };

  const handleSavePublication = async () => {
    if (!valueHour || !valueMinute) {
      notifyErrorCustom('Selecione um horário!');

      setLoading(false);
      return;
    }

    if (!selectedValuesString.length) {
      notifyErrorCustom('Selecione ao menos uma rede social!');
      setLoading(false);
      return;
    }

    if (selectedValues[3] && !tiktokPrivacyLevel) {
      notifyErrorCustom(
        'Selecione a privacidade da sua publicação para o TikTok'
      );
      setLoading(false);
      return;
    }

    if (tiktokContentDisclosure && !tiktokYourBrand && !tiktokBrandedContent) {
      notifyErrorCustom(
        'Ao habilitar "Divulgação de Conteúdo" do TikTok você precisa informar se seu conteudo divulga "Sua marca" ou "Conteúdo de marca"'
      );
      setLoading(false);
      return;
    }

    const dateSelected = dayjs(selectedDate)
      .hour(valueHour)
      .minute(valueMinute);

    const imageUrlBuket = imageUrl;

    let publicationImageBucket = await returnUploadImageBucket(imageUrlBuket);

    if (publicationImageBucket) {
      let businessId;

      if (clientData?.uuid) {
        const { data } = await getBusinessByClientId(clientData?.uuid);
        businessId = data[0]?.uuid;
      } else {
        const { data } = await BusinessService.getBusinessByLogin();

        businessId = data[0]?.uuid;
      }

      let publicationBody = {
        headline: '',
        legend: publicationLegend,
        publicationImageUrl: publicationImageBucket?.fileUrl,
        publicationImageUrlName: publicationImageBucket?.fileName,
        sketch: false,
        publicationDate: dateSelected,
        scheduleDate: dateSelected,
        objective: '',
        businessClientId: clientData?.uuid ? clientData?.uuid : undefined,
        socialTypes: selectedValuesString,
        guestId: JSON.parse(window.localStorage.getItem('userData'))
          .guestUserId,
        generateArtJson: {
          params: [],
        },
      };

      const publicationResponse = await PublicationService.postPublication(
        businessId,
        selectedValuesString,
        publicationBody
      );

      publicationIdRef.current = [
        { uuid: publicationResponse?.data?.uuid, classification: null },
      ];

      if (
        publicationResponse?.responseStatus === 200 ||
        publicationResponse?.responseStatus === 204
      ) {
        if (selectedValues[3]) {
          let bodyTiktokPublication = {
            publicationId: publicationResponse.data.uuid,
            privacyLevel: tiktokPrivacyLevel,
            disableComment: !tiktokComment,
            musicImage: tiktokMusic,
            brandContentToggle: tiktokBrandedContent,
            brandOrganicToggle: tiktokYourBrand,
          };

          const tiktokPublicationReturn =
            await TiktokService.postTiktokPublication(bodyTiktokPublication);
        }

        notifySuccessCustom('Publicação agendada com sucesso!');
        setStep('stepPublicationSuccess');

        setLoading(false);
      } else {
        notifyErrorCustom('Erro ao agendar publicação!');

        setLoading(false);
      }
    } else {
      notifyErrorCustom('Erro ao salvar arte!');
      setLoading(false);
      return;
    }

    setLoading(false);
  };

  const handlePublicationModeMovie = (movieType) => {
    setPublicationMovieType(movieType.id);
  };

  async function fetchBlob(url) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Erro ao buscar o Blob');
    }
    return await response.blob();
  }

  const handleSavePublicationMovie = async () => {
    // return
    let businessId;

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid);
      businessId = data[0]?.uuid;
    } else {
      const { data } = await BusinessService.getBusinessByLogin();

      businessId = data[0]?.uuid;
    }

    if (!valueHour || !valueMinute) {
      notifyErrorCustom('Selecione um horário!');

      setLoading(false);
      return;
    }

    if (!selectedValuesString.length) {
      notifyErrorCustom('Selecione ao menos uma rede social!');
      setLoading(false);
      return;
    }

    if (!publicationMovieType) {
      notifyErrorCustom('Selecione o tipo de publicação!');
      setLoading(false);
      return;
    }

    if (videoUrl.file.size < 75) {
      notifyErrorCustom(
        'O tamanho do vídeo é muito pequeno! O tamanho mínimo é de 75KB'
      );
      setLoading(false);
      return;
    }

    if (videoUrl.file.size > 500000000) {
      notifyErrorCustom('O tamanho do vídeo excede o limite de 500MB!');
      setLoading(false);
      return;
    }

    if (selectedValues[3] && !tiktokPrivacyLevel) {
      notifyErrorCustom(
        'Selecione a privacidade da sua publicação para o TikTok'
      );
      setLoading(false);
      return;
    }

    if (tiktokContentDisclosure && !tiktokYourBrand && !tiktokBrandedContent) {
      notifyErrorCustom(
        'Ao habilitar "Divulgação de Conteúdo" do TikTok você precisa informar se seu conteudo divulga "Sua marca" ou "Conteúdo de marca"'
      );
      setLoading(false);
      return;
    }

    const dateSelected = dayjs(selectedDate)
      .hour(valueHour)
      .minute(valueMinute);

    console.log('videoUrl ====> ', videoUrl);

    const formData = new FormData();

    const movieBlob = await fetchBlob(videoUrl.url);

    formData.append('file', movieBlob, 'video.mp4');

    const publicationMovieBucketResponse =
      await PublicationService.postMovieBucket(formData);

    // console.log(
    //   'publicationMovieBucketResponse',
    //   publicationMovieBucketResponse
    // )

    let body = {
      // legend: publicationLegend,
      legend: publicationMovieType !== 2 ? publicationLegend : '',

      publicationMovieUrl: publicationMovieBucketResponse?.data?.url,

      publicationMovieUrlName: publicationMovieBucketResponse?.data?.name,

      movieName: videoUrl.file.name,

      movieSize: `${(videoUrl.file.size / 1024 / 1024).toFixed(2)}MB`,
      movieType: 'mp4',

      // status: 'pending',

      // publicationMode: 'FEED',
      publicationMode: publicationMovieType
        ? [
            {
              id: 1,
              label: 'Feed',
              value: 'REELS',
            },
            {
              id: 2,
              label: 'Stories',
              value: 'STORIES',
            },
          ].find((item) => item?.id === publicationMovieType)?.value
        : 'FEED',

      scheduleDate: dateSelected,
      publicationDate: dateSelected,

      objective: '',

      businessClientId: clientData?.uuid ? clientData?.uuid : undefined,
      guestId: JSON.parse(window.localStorage.getItem('userData')).guestUserId,
    };

    const publicationMovieResponse =
      await PublicationService.postPublicationMovie(
        businessId,
        selectedValuesString,
        body
      );

    console.log('publicationMovieResponse', publicationMovieResponse);

    if (selectedValues[3]) {
      let bodyTiktokPublication = {
        publicationMovieId: publicationMovieResponse.data.uuid,
        privacyLevel: tiktokPrivacyLevel,
        disableComment: !tiktokComment,
        disableDuet: !tiktokDuet,
        disableStitch: !tiktokStitch,
        brandContentToggle: tiktokBrandedContent,
        brandOrganicToggle: tiktokYourBrand,
      };

      const tiktokPublicationReturn = await TiktokService.postTiktokPublication(
        bodyTiktokPublication
      );
    }

    notifySuccessCustom('Publicação agendada com sucesso!');

    setTimeout(() => {
      setLoading(false);
      navigate('/HomePage');
    }, 3000);

    //
  };

  const handleSelectedIntem = (item) => {
    setValueItem(item.id);
  };

  const handleTiktokContentDisclosure = () => {
    if (tiktokPrivacyLevel === 'SELF_ONLY') {
      setTiktokContentDisclosure(false);
      setTiktokYourBrand(false);
      setTiktokBrandedContent(false);
      notifyErrorCustom(
        'Configorações de "Divulgação de Conteúdo" não pode ser habilitada para publicações privadas'
      );
      return;
    }
    if (tiktokContentDisclosure) {
      setTiktokContentDisclosure(false);
      setTiktokYourBrand(false);
      setTiktokBrandedContent(false);
      setTiktokBrandedInfo(
        'Você precisa indicar se seu conteúdo promove você mesmo, um terceiro ou ambos.'
      );
    } else {
      setTiktokContentDisclosure(true);
    }
  };

  const handleYourBrand = () => {
    if (!tiktokYourBrand && !tiktokBrandedContent) {
      setTiktokYourBrand(true);
      setTiktokBrandedInfo(
        'Sua foto/vídeo será rotulado como "Conteúdo promocional"'
      );
    } else if (!tiktokYourBrand && tiktokBrandedContent) {
      setTiktokYourBrand(true);
      setTiktokBrandedInfo('Sua foto/vídeo será rotulado como "Parceria paga"');
    } else if (tiktokYourBrand && tiktokBrandedContent) {
      setTiktokYourBrand(false);
      setTiktokBrandedInfo('Sua foto/vídeo será rotulado como "Parceria paga"');
    } else {
      setTiktokYourBrand(false);
      setTiktokBrandedInfo(
        'Você precisa indicar se seu conteúdo promove você mesmo, um terceiro ou ambos.'
      );
    }
  };

  const handleYourBrandingContent = () => {
    if (!tiktokBrandedContent && !tiktokYourBrand) {
      setTiktokBrandedContent(true);
      setTiktokBrandedInfo('Sua foto/vídeo será rotulado como "Parceria paga"');
    } else if (!tiktokBrandedContent && tiktokYourBrand) {
      setTiktokBrandedContent(true);
      setTiktokBrandedInfo('Sua foto/vídeo será rotulado como "Parceria paga"');
    } else if (tiktokBrandedContent && tiktokYourBrand) {
      setTiktokBrandedContent(false);
      setTiktokBrandedInfo(
        'Sua foto/vídeo será rotulado como "Conteúdo promocional""'
      );
    } else {
      setTiktokBrandedContent(false);
      setTiktokBrandedInfo(
        'Você precisa indicar se seu conteúdo promove você mesmo, um terceiro ou ambos.'
      );
    }
  };

  const handlePrivacyLevel = (value) => {
    if (value === 'SELF_ONLY') {
      setTiktokContentDisclosure(false);
      setTiktokYourBrand(false);
      setTiktokBrandedContent(false);
      notifyWarningCustom(
        'Configorações de "Divulgação de Conteúdo" não pode ser habilitada para publicações privadas'
      );
    }
    setTiktokPrivacyLevel(value);
  };

  const loadGptOutput = async () => {
    // console.log('gptInput ====> ', gptInput)

    let businesData = undefined;
    let voiceToneData = '';

    if (clientData?.uuid) {
      const { data } = await getBusinessByClientId(clientData?.uuid);
      businesData = data[0];

      voiceToneData = await VoiceToneService.getAllVoiceToneByBusinessId(
        businesData?.uuid
      );
    } else {
      const { data } = await BusinessService.getBusinessByLogin();

      businesData = data[0];

      voiceToneData = await VoiceToneService.getAllVoiceToneByBusiness();
    }

    // console.log('voiceToneData ====> ', voiceToneData?.data[0]?.tone)

    const body = {
      flow: 'own_publication',
      prompt: 'legend',

      niche: businesData?.BusinessNiche?.name,
      subniche: businesData?.subNiche,
      input: gptInput,
      voice_tone: voiceToneData?.data[0]?.tone,
      business_description: businesData?.description,
    };

    // console.log('body ====> ', body)

    const response = await postPromptGPT(body);

    // console.log('response ====> ', response)

    if (response?.data?.status === 200) {
      setPublicationLegend(response?.data?.data?.legenda);
    } else {
      notifyErrorCustom('Erro ao gerar legenda!');
    }

    setLoading(false);
  };

  const ScheduleInfoField = () => {
    const hours = [
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00 ',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00 ',
      '00:00',
    ];

    return (
      <div
        style={{
          flex: 1,
          flexDirection: 'column',
          overflowY: 'auto',
          WebkitOverflowScrolling: 'touch',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',

          height: 'calc(100vh - 176px)',
        }}
      >
        <Loading enable={loading} />

        <div className={styles.card}>
          <div className={styles.showPath}>
            <Picture color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>{'Publicar em'}</p>
          </div>

          <div className={styles.iconsField}>
            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
            >
              <Pinterest color={'#AAAAAA'} width={24} height={24} />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
              onClick={() => {
                setPublicationMovieType(undefined);
                handleClick(1);
              }}
            >
              <Linkedin
                color={selectedValues[1] ? '#6A0098' : '#AAAAAA'}
                width={24}
                height={24}
              />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
              onClick={() => {
                setPublicationMovieType(undefined);
                handleClick(4);
              }}
            >
              <Instagram
                color={selectedValues[4] ? '#6A0098' : '#AAAAAA'}
                width={24}
                height={24}
              />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
              onClick={() => handleClick(3)}
            >
              <TikTok
                color={selectedValues[3] ? '#6A0098' : '#AAAAAA'}
                width={24}
                height={24}
              />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
              onClick={() => handleClick(2)}
            >
              <Twitter
                color={selectedValues[2] ? '#6A0098' : '#AAAAAA'}
                width={24}
                height={24}
              />
            </div>

            {/* <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
            >
              <Pinterest color={'#AAAAAA'} width={24} height={24} />
            </div> */}
          </div>
        </div>

        {publicationMovieType !== 2 && (
          <div
            style={{
              marginTop: 30,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
              cursor: 'default',
            }}
            className={styles.selectedModalCard}
          >
            <div
              className={styles.form_container_input}
              style={{
                cursor: 'default',
              }}
            >
              <label
                style={{
                  fontSize: 14,
                  color: '#190027',
                  fontWeight: 500,
                }}
              >
                Legenda
              </label>

              <textarea
                readOnly={false}
                name=""
                id=""
                cols="30"
                rows="10"
                value={publicationLegend}
                style={{
                  width: '100%',
                  height: 130,
                  borderRadius: 6,
                  border: '1px solid #ededed',
                  backgroundColor: 'rgba(224, 224, 224, 1)',
                  padding: '10px',
                  color: '#373737',
                  resize: 'none',
                  outline: 'none',
                  fontFamily: 'Poppins',
                  fontSize: 12,
                }}
                onChange={(e) => setPublicationLegend(e.target.value)}
                placeholder="Digite aqui a legenda desta publicação"
              />

              <div
                style={{
                  display: 'flex',
                  gap: 20,
                }}
              >
                <input
                  value={gptInput}
                  onChange={(e) => setGptInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setLoading(true);
                      loadGptOutput();
                    }
                  }}
                  type="text"
                  placeholder="Digite sobre seu post e clique em aplicar para que a IA te de uma sugestão de legenda"
                  style={{
                    width: '100%',
                    height: 40,
                    borderRadius: 5,
                    border: '1px solid #ededed',
                    backgroundColor: '#ededed',
                    padding: '0 10px',
                    marginTop: 10,
                    marginBottom: 20,

                    color: '#190027',
                  }}
                />
                <div
                  onClick={() => {
                    setLoading(true);
                    loadGptOutput();
                  }}
                  style={{
                    display: 'flex',
                    marginTop: 10,
                    height: 40,
                    width: 100,
                    borderRadius: 5,
                    backgroundColor: '#6A0098',

                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <div
                    style={{
                      color: '#fff',
                      textAlign: 'center',
                      fontSize: 14,
                    }}
                  >
                    Aplicar
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          style={{
            marginTop: 30,
          }}
          className={styles.card}
        >
          <div className={styles.showPath}>
            <Calendar2 color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>{'Data e Horário'}</p>
          </div>

          <div className={styles.box_calendar}>
            <div
              style={{
                flex: 1,
              }}
            >
              <SchedulerCalendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </div>

            <div className={styles.container_hour_carousel}>
              <p className={styles.container_hour_carousel_title}>Horário</p>
              <CarouselHours
                items={hours}
                setValueMinute={setValueMinute}
                valueMinute={valueMinute}
                setValueHour={setValueHour}
                valueHour={valueHour}
              />
            </div>
          </div>
        </div>

        {tiktokCreatorInfo !== false && selectedValues[3] && (
          <div
            style={{
              marginTop: 30,
            }}
            className={styles.card}
          >
            <div className={styles.showPath}>
              <Subtract color={'#AAAAAA'} width={20} height={20} />
              <p
                className={styles.patchTitle}
              >{`Configurações do TikTok - ${tiktokCreatorInfo.creator_nickname}`}</p>
              <img
                style={{
                  maxWidth: '40px',
                  borderRadius: '200px',
                }}
                src={`${tiktokCreatorInfo.creator_avatar_url}`}
              />
            </div>

            <div
              style={{
                marginTop: 30,
                backgroundColor: '#fff8c4',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                borderColor: '#ffd700',
              }}
              className={styles.selectedModalCard}
            >
              <div>
                <TikTokIcon width={12} height={12} />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                <div>
                  <div className={styles.selectedModalTextTitle}>Aviso</div>
                  <div className={styles.selectedModalTextSubtitle}>
                    Após a publicação, pode levar alguns minutos para que o
                    conteúdo seja processado e fique visível em seu perfil.
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 30,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <div>
                <LockClosed width={12} height={12} />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                <div className={styles.selectedModalTextSubtitle}>
                  <SelectSquare
                    htmlFor="select"
                    placeholder="Selecione a privacidade da sua publicação"
                    padding="10px 10px 10px 15px"
                    value={dropDownTiktok?.value}
                    options={dropDownTiktok}
                    onClickItem={({ value }) => {
                      handlePrivacyLevel(value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 18,
                backgroundColor: tiktokComment
                  ? '#f5eaf9'
                  : tiktokCreatorInfo.comment_disabled
                  ? '#edeae8'
                  : '#FBFBFB', //'#6A0098',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                pointerEvents: tiktokCreatorInfo.comment_disabled
                  ? 'none'
                  : 'auto',
                opacity: '0.5 : 1',
              }}
              className={styles.selectedModalCard}
              onClick={() =>
                tiktokComment ? setTiktokComment(false) : setTiktokComment(true)
              }
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                  backgroundColor: tiktokComment
                    ? '#6A0098'
                    : tiktokCreatorInfo.comment_disabled
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>Comentario</div>
                <div className={styles.selectedModalTextSubtitle}>
                  Permitir que o outros usuários comentem sua publicação
                </div>
              </div>
            </div>
            {!videoUrl && (
              <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokMusic ? '#f5eaf9' : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                }}
                className={styles.selectedModalCard}
                onClick={() =>
                  tiktokMusic ? setTiktokMusic(false) : setTiktokMusic(true)
                }
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokMusic ? '#6A0098' : '#FBFBFB', //'#6A0098',
                  }}
                ></div>
                <div>
                  <div className={styles.selectedModalTextTitle}>Música</div>
                  <div className={styles.selectedModalTextSubtitle}>
                    Permitir que o Tiktok adicione música em sua publicação?
                  </div>
                  {tiktokMusic !== false && (
                    <div className={styles.warningBox}>
                      Ao selecionar, você concorda com a{' '}
                      <a
                        target="_blank"
                        href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                        rel="noreferrer"
                      >
                        {' '}
                        Confirmação de Uso de Música do TikTok
                      </a>
                      .
                    </div>
                  )}
                </div>
              </div>
            )}
            {videoUrl && (
              <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokDuet
                    ? '#f5eaf9'
                    : tiktokCreatorInfo.duet_disabled
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  pointerEvents: tiktokCreatorInfo.duet_disabled
                    ? 'none'
                    : 'auto',
                  opacity: '0.5 : 1',
                }}
                className={styles.selectedModalCard}
                onClick={() =>
                  tiktokDuet ? setTiktokDuet(false) : setTiktokDuet(true)
                }
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokDuet
                      ? '#6A0098'
                      : tiktokCreatorInfo.duet_disabled
                      ? '#edeae8'
                      : '#FBFBFB', //'#6A0098',
                  }}
                ></div>
                <div>
                  <div className={styles.selectedModalTextTitle}>Dueto</div>
                  <div className={styles.selectedModalTextSubtitle}>
                    Permitir que outros usuários façam dueto com sua publicação.
                  </div>
                </div>
              </div>
            )}
            {videoUrl && (
              <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokStitch
                    ? '#f5eaf9'
                    : tiktokCreatorInfo.stitch_disabled
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  pointerEvents: tiktokCreatorInfo.stitch_disabled
                    ? 'none'
                    : 'auto',
                  opacity: '0.5 : 1',
                }}
                className={styles.selectedModalCard}
                onClick={() =>
                  tiktokStitch ? setTiktokStitch(false) : setTiktokStitch(true)
                }
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokStitch
                      ? '#f5eaf9'
                      : tiktokCreatorInfo.stitch_disabled
                      ? '#edeae8'
                      : '#FBFBFB', //'#6A0098',
                  }}
                ></div>
                <div>
                  <div className={styles.selectedModalTextTitle}>Stitch</div>
                  <div className={styles.selectedModalTextSubtitle}>
                    Permitir que outros usuários façam stitch com sua
                    publicação.
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                marginTop: 18,
                backgroundColor: tiktokContentDisclosure
                  ? '#f5eaf9'
                  : '#FBFBFB', //'#6A0098',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                // pointerEvents: tiktokCreatorInfo.stitch_disabled
                //   ? 'none'
                //   : 'auto',
                opacity: '0.5 : 1',
              }}
              className={styles.selectedModalCard}
              onClick={
                () => handleTiktokContentDisclosure()
                // tiktokContentDisclosure
                //   ? setTiktokContentDisclosure(false)
                //   : setTiktokContentDisclosure(true),
              }
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                  backgroundColor: tiktokContentDisclosure
                    ? '#6A0098'
                    : '#FBFBFB', //'#6A0098',
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>
                  Divulgação de Conteúdo
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  Indique se este conteúdo promove você, uma marca, produto ou
                  serviço.
                </div>
              </div>
            </div>
            {tiktokContentDisclosure && (
              <div
                style={{
                  marginTop: 30,
                  backgroundColor: '#fff8c4',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  borderColor: '#ffd700',
                }}
                className={styles.selectedModalCard}
              >
                <div>
                  <TikTokIcon width={12} height={12} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div>
                    <div className={styles.selectedModalTextTitle}>Aviso</div>
                    <div className={styles.selectedModalTextSubtitle}>
                      {tiktokBrandedInfo}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                marginTop: 18,
                backgroundColor: tiktokYourBrand
                  ? '#f5eaf9'
                  : !tiktokContentDisclosure
                  ? '#edeae8'
                  : '#FBFBFB', //'#6A0098',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                pointerEvents: !tiktokContentDisclosure ? 'none' : 'auto',
                opacity: '0.5 : 1',
              }}
              className={styles.selectedModalCard}
              onClick={() => handleYourBrand()}
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                  backgroundColor: tiktokYourBrand
                    ? '#6A0098'
                    : !tiktokContentDisclosure
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>Sua marca </div>
                <div className={styles.selectedModalTextSubtitle}>
                  você está promovendo a si mesmo ou seu próprio negócio. Este
                  conteúdo será classificado como Brand Organic.
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: 18,
                backgroundColor: tiktokBrandedContent
                  ? '#f5eaf9'
                  : !tiktokContentDisclosure
                  ? '#edeae8'
                  : '#FBFBFB', //'#6A0098',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                pointerEvents: !tiktokContentDisclosure ? 'none' : 'auto',
                opacity: '0.5 : 1',
              }}
              className={styles.selectedModalCard}
              onClick={() => handleYourBrandingContent()}
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                  backgroundColor: tiktokBrandedContent
                    ? '#6A0098'
                    : !tiktokContentDisclosure
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>
                  Conteúdo de marca
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  Você está promovendo outra marca ou um terceiro. Este conteúdo
                  será classificado como Branded Content.
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            marginTop: 30,
          }}
          className={styles.card}
        >
          <div className={styles.showPath}>
            <Subtract color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>{'Configurações Adicionais'}</p>
          </div>

          <div
            onClick={() => setOppenedModalLocale(true)}
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <PinMapsIcon color={'#610f92'} size={'24'} />
            <div>
              <div className={styles.selectedModalTextTitle}>Localização</div>
              <div className={styles.selectedModalTextSubtitle}>
                Adicione uma localização
              </div>
            </div>
          </div>

          <div
            onClick={() => setOppenedModalPersons(true)}
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <MorePersonsIcon color={'#610f92'} size={'24'} />
            <div>
              <div className={styles.selectedModalTextTitle}>
                Marcar pessoas
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                Marque pessoas ou empresas a esta publicação
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <FacebookChatIcon color={'#610f92'} size={'24'} />

            <div>
              <div className={styles.selectedModalTextTitle}>
                Receber Mensagens
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                Permitam que pessoas te enviem mensagens a partir desta
                publicação (Apenas Facebook)
              </div>
            </div>
          </div>

          <div
            onClick={() => setOppennedModalLinks(true)}
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <LinksIcon color={'#610f92'} size={'22'} />
            <div>
              <div className={styles.selectedModalTextTitle}>
                Adicionar Links
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                Adicione links externos a sua publicação (Apenas Facebook)
              </div>
            </div>
          </div>

          <div
            onClick={() => setOppennedModalHashtags(true)}
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <HashtagIcon color={'#610f92'} size={'22'} />
            <div>
              <div className={styles.selectedModalTextTitle}>
                Adicionar Hashtags
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                Segmente sua publicação para os melhores públicos
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
          }}
          className={styles.card}
        >
          <div className={styles.showPath}>
            <Subtract color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>
              {'Configurações de Privacidade'}
            </p>
          </div>

          <div
            style={{
              marginTop: 30,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <div
              style={{
                width: 34,
                height: 34,
                border: '1px solid #dcdcdc',
                borderRadius: 200,
              }}
            ></div>
            <div>
              <div className={styles.selectedModalTextTitle}>Público</div>
              <div className={styles.selectedModalTextSubtitle}>
                Qualquer pessoa dentro ou fora do Facebook poderá ver sua
                publicação.
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <div
              style={{
                width: 34,
                height: 34,
                border: '1px solid #dcdcdc',
                borderRadius: 200,
              }}
            ></div>
            <div>
              <div className={styles.selectedModalTextTitle}>Restrito</div>
              <div className={styles.selectedModalTextSubtitle}>
                Escolha certas pessoas no Facebook que podem ver sua publicação.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SchedulePhotosField = () => {
    const handleFileSelect = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImageUrl(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    };

    const handleClick = () => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = handleFileSelect;
      input.click();
    };

    // Handler para quando o usuário selecionar um arquivo de vídeo
    const handleVideoChange = async (event) => {
      const file = event.target.files[0];

      if (file && mediaInfoRef.current) {
        if (selectedValues[3]) {
          mediaInfoRef.current
            .analyzeData(file.size, readChunk(file))
            .then(async (result) => {
              const videoTrack = result.media.track.find(
                (track) => track['@type'] === 'Video'
              );

              const retValid = await validateVideo(videoTrack);

              if (!retValid) {
                setVideoUrl();
                return;
              }
            })
            .catch((error) => {
              console.error('Error analyzing video file', error);
            });
        }
        const url = URL.createObjectURL(file);

        setVideoUrl({
          url,
          file,
        });
      }
    };

    const validateVideo = async (metadata) => {
      const { CodecID, FrameRate, Height, Width } = metadata;
      // Codec
      // H.264 (MPEG-4 AVC): Geralmente identificado como avc1.
      // H.265 (HEVC): Geralmente identificado como hev1 ou hvc1.
      // VP8 e VP9: Geralmente identificados como V_VP8 e V_VP9 (para WebM).
      if (
        ![
          'avc1',
          'hev1',
          'hvc1',
          'vp08',
          'vp09',
          'H.264',
          'H.265',
          'VP8',
          'VP9',
        ].includes(CodecID)
      ) {
        notifyErrorCustom(
          'Codec inválido. O TikTok suporta H.264, H.265, VP8 e VP9.'
        );
        // setIsTiktokValidVideo(false)
        return false;
      }

      if (FrameRate < 23 || FrameRate > 60) {
        notifyErrorCustom(
          'A taxa de quadros deve estar entre 23 e 60 FPS para o TikTok.'
        );
        setIsTiktokValidVideo(false);
        return false;
      }

      if (Width < 360 || Height < 360 || Width > 4096 || Height > 4096) {
        notifyErrorCustom(
          'As dimensões do vídeo devem estar entre 360 e 4096 pixels em altura e largura para o TikTok.'
        );
        setIsTiktokValidVideo(false);
        return false;
      }
      return true;
    };

    const handleClickMovie = () => {
      const input = document.createElement('input');
      input.type = 'file';
      // input.accept = 'video/*'
      input.accept = 'video/mp4';
      //adicione um limite de tamanho maximo para o video

      input.onchange = handleVideoChange;
      input.click();
    };

    // Handler para quando o usuário selecionar um arquivo de imagem

    const handleClickMovieImage = () => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = handleImageChange;
      input.click();
    };

    const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const url = URL.createObjectURL(file);
        setPosterUrl(url);
      }
    };
    const storage = JSON.parse(
      window.localStorage.getItem('userData')
    ).userEmail;
    const userInfo = {
      username: storage, // REQUIRED, must be unique
    };

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',

          width: 480,
          marginLeft: 20,
          gap: 20,

          height: 'calc(100vh - 176px)',
          overflowY: 'auto',
          WebkitOverflowScrolling: 'touch',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
      >
        <div
          style={{
            backgroundColor: imageUrl || videoUrl ? '#fbfbfb' : '#eeeeee',
            border: imageUrl || videoUrl ? 'none' : '0.5px solid #190027',
            borderRadius: imageUrl || videoUrl ? 0 : 30,
            flex: 1,
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          <ProductFruits
            workspaceCode="FzMKHdjfiHGoWeMW"
            language="en"
            user={userInfo}
          />
          {!imageUrl && !videoUrl && (
            <div
              style={{
                display: 'flex',

                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 20,
              }}
            >
              <div
                className={styles.modal_title}
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                  textAlign: 'center',
                }}
              >
                Adicionar nova imagem
              </div>

              <div
                onClick={handleClick}
                style={{
                  // padding: 54,
                  height: 140,
                  width: 140,
                  backgroundColor: '#ffffff',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 20,
                  gap: 4,
                }}
              >
                <Clips color={'#600f93'} width={24} height={24} />
                <div
                  className={styles.modal_title}
                  style={{
                    fontSize: 12,
                    textAlign: 'center',
                  }}
                >
                  Galeria
                </div>
              </div>

              {(selectedValues[1] ||
                selectedValues[3] ||
                selectedValues[4]) && (
                <div
                  onClick={handleClickMovie}
                  style={{
                    height: 140,
                    width: 140,
                    backgroundColor: '#ffffff',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 20,
                    gap: 4,
                  }}
                >
                  <MovieIcon color={'#600f93'} size={26} />
                  <div
                    className={styles.modal_title}
                    style={{
                      fontSize: 12,
                      textAlign: 'center',
                    }}
                  >
                    Vídeo
                  </div>
                </div>
              )}
            </div>
          )}

          {imageUrl && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                width: '100%',
              }}
            >
              <SelectSquare
                backgroundColor={'#EDEDED'}
                border={true}
                borderColor={'#dadada'}
                placeholder={'Selecione'}
                arrowDirection="down"
                options={[
                  {
                    id: 1,
                    label: 'Previa Feed Do Facebook',
                  },
                  {
                    id: 2,
                    label: 'Previa Feed Do Instagram',
                  },
                  {
                    id: 3,
                    label: 'Previa Stories Do Facebook',
                  },
                  {
                    id: 4,
                    label: 'Previa Stories Do Instagram',
                  },
                ]}
                fontSize={12}
                onClickItem={handleSelectedIntem}
              />

              <ImageRender
                image={imageUrl}
                type={valueItem}
                legend={publicationLegend}
              />
            </div>
          )}

          {videoUrl && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                width: '100%',

                padding: 20,
              }}
            >
              <SelectSquare
                backgroundColor={'#EDEDED'}
                border={true}
                borderColor={'#dadada'}
                placeholder={'Tipo de publicação'}
                arrowDirection="down"
                options={
                  selectedValues[1]
                    ? [
                        {
                          id: 1,
                          label: 'Feed',
                          value: 'FEED',
                        },
                      ]
                    : [
                        {
                          id: 1,
                          label: 'Feed',
                          value: 'FEED',
                        },
                        {
                          id: 2,
                          label: 'Stories',
                          value: 'STORIES',
                        },
                      ]
                }
                fontSize={12}
                onClickItem={handlePublicationModeMovie}
                defaultSelected={[
                  {
                    id: 1,
                    label: 'Feed',
                    value: 'FEED',
                  },
                  {
                    id: 2,
                    label: 'Stories',
                    value: 'STORIES',
                  },
                ].find((item) => item?.id === publicationMovieType)}
              />

              {videoUrl && (
                <ReactPlayer
                  style={{ marginTop: 20 }}
                  url={videoUrl?.url}
                  controls
                  width="100%"
                  height={posterUrl ? 300 : '100%'}
                  light={posterUrl}
                  onProgress={handleProgress}
                />
              )}

              {videoUrl && (
                <>
                  <p className={styles.patchTitle}>
                    {'Nome: ' + videoUrl.file.name}
                  </p>
                  <p className={styles.patchTitle}>{`Tamanho: ${(
                    videoUrl.file.size /
                    1024 /
                    1024
                  ).toFixed(2)}MB`}</p>
                </>
              )}

              <div
                style={{
                  marginTop: 20,
                }}
              >
                <div
                  onClick={handleClickMovieImage}
                  style={{
                    height: 120,
                    width: 120,
                    backgroundColor: 'rgb(237, 237, 237)',
                    border: '1px solid rgb(218, 218, 218);',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 20,
                    gap: 4,
                  }}
                >
                  <Picture color={'#600f93'} size={26} />
                  <div
                    className={styles.modal_title}
                    style={{
                      fontSize: 12,
                      textAlign: 'center',
                    }}
                  >
                    Capa
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const ButtonsBottomField = () => {
    return (
      <div
        style={{
          height: 70,
        }}
      >
        <div className={styles.button_nav}>
          <button
            className={styles.button_back}
            onClick={() => {
              navigate('/NewPostPage');
            }}
          >
            <p className={styles.button_title}>Voltar</p>
          </button>

          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <button
              className={styles.button_next}
              disabled={loading}
              // onClick={handleSavePublication}
              onClick={() => {
                setLoading(true);

                if (videoUrl) {
                  handleSavePublicationMovie();
                  return;
                }

                handleSavePublication();
              }}
            >
              <p className={styles.button_title}>Finalizar</p>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.container_page}>
        <div
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          {ScheduleInfoField()}
          {SchedulePhotosField()}
        </div>

        <ButtonsBottomField />

        <ModalComponent
          isOpen={oppenedModalLocale}
          onClose={() => setOppenedModalLocale(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Localização</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppenedModalLocale(false);
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Digite aqui..."
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppenedModalLocale(false);
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppenedModalPersons}
          onClose={() => setOppenedModalPersons(false)}
          style={{
            width: 600,
            height: 280,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Marcar Pessoas</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppenedModalPersons(false);
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <div className={styles.iconsField} style={{ marginTop: 20 }}>
              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Pinterest color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Linkedin color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Instagram color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <TikTok color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Twitter color={'#AAAAAA'} width={24} height={24} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Quem Você gostaria de Marcar?"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppenedModalPersons(false);
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppennedModalLinks}
          onClose={() => setOppennedModalLinks(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Adicionar Link</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppennedModalLinks(false);
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Insira um link público e válido aqui"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppennedModalLinks(false);
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppennedModalHashtags}
          onClose={() => setOppennedModalHashtags(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>Adicionar Hashtags</div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppennedModalHashtags(false);
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Insira as hashtags e separe as mesmas com uma vírgula (,)"
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppennedModalHashtags(false);
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>
      </div>
    </>
  );
}

export default StepMyPublication;

// StepMyPublication.propTypes = {}
