import { useRef, useState, useEffect } from 'react'
import styles from './styles.module.css'

import { useNavigate } from 'react-router-dom'
import { Eye, FavoriteStars, FavoriteStarsPress } from '../../assets/svgs/icons'
import Calendar from '../../components/Calendar'
import { PublicationService } from '../../services'

import logoPostei from '../../assets/logos/postei-logo-color.png'
import companyIcon from '../../assets/imgs/enterprise.png'
import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import { motion } from 'framer-motion'

function SchedulingPage() {
  const navigation = useNavigate()

  const [expandedStates, setExpandedStates] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [agendamento, setAgendamento] = useState([])
  const [scheduledDays, setScheduledDays] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date().toDateString())
  const [dateSchedule, setDateSchedule] = useState()

  const location = useLocation()

  const [currentDate] = useState(new Date()) // Get the current date

  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear())
  const [currentMonth, setCurrentMonth] = useState(currentDate.getMonth())

  useEffect(() => {
    setDateSchedule(location.state?.dateSchedule)
    loadClientPost()
  }, [selectedDate, currentMonth])

  const loadClientPost = async () => {
    const options = { day: 'numeric', month: 'numeric', year: 'numeric' }
    const formattedDate = new Date(selectedDate).toLocaleDateString(
      'pt-BR',
      options
    )

    const [day, month, year] = formattedDate.split('/')

    let newMonth =
      currentMonth.toString().length === 1 && currentMonth + 1 < 10
        ? `0${currentMonth + 1}`
        : currentMonth + 1

    const { data, responseStatus } =
      await PublicationService.getAllPublicationByUsersByDate(
        day,
        newMonth,
        year
      )

    if (responseStatus === 200 || responseStatus === 204) {
      console.log(
        'data?.publicationsByDateSelected ===>',
        data?.publicationsByDateSelected
      )

      const reorderedDataByDate = data?.publicationsByDateSelected?.map(
        (item) => {
          return {
            ...item,
            data: item.data.sort((a, b) => {
              return (
                new Date(a.publicationDateDay) - new Date(b.publicationDateDay)
              )
            }),
          }
        }
      )

      setAgendamento(reorderedDataByDate)
      setScheduledDays(data?.ScheduleDatesCalendar)
    }
  }

  let newOrderknowLedgeChannel = agendamento?.slice()

  newOrderknowLedgeChannel?.sort((a, b) => {
    if (a.favorite === true) return -1
    if (b.favorite === true) return 1

    return 0
  })

  // const handleNavigate = (route) => {
  //   navigation(`${route}`)
  // }

  const truncateTitle = (title, maxLength) => {
    if (!title) {
      return ''
    }

    if (title.length > maxLength) {
      return title.slice(0, maxLength) + '...'
    }
    return title
  }

  function hadleUpdateFavorite(clienteId) {
    const updatedData = newOrderknowLedgeChannel?.map((item) => {
      if (item.businessClientId === clienteId) {
        return {
          ...item,
          favorite: !item.favorite,
        }
      }
      return item
    })

    setAgendamento(updatedData)
  }

  const renderHours = (dateHourString) => {
    const dateObject = new Date(dateHourString)

    if (isNaN(dateObject.getTime())) {
      // Verifica se a conversão para objeto de data foi bem-sucedida
      return 'Formato de data inválido'
    }

    return (
      dayjs(dateHourString).hour() +
      ':' +
      dayjs(dateHourString).minute().toString().padStart(2, '0')
    )
  }

  const formatDate = (dateHour) => {
    const dateObject = new Date(dateHour)
    const formattedDate = dateObject.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric', // Removido '2-digit'
      day: 'numeric', // Removido '2-digit'
    })

    const formattedDateWithHyphens = formattedDate.replace(/\//g, '-')
    return formattedDateWithHyphens
  }

  const handleExpandArea = (agendamentoId, scheduleId) => {
    setExpandedStates((prevExpandedStates) => {
      // Crie uma cópia do objeto de estados expandidos
      const newExpandedStates = { ...prevExpandedStates }

      // Verificar se essa <td> já está expandida
      const existingExpandedArray = Array.isArray(
        newExpandedStates[agendamentoId]
      )
        ? newExpandedStates[agendamentoId]
        : []

      // Verificar se o scheduleId já existe no array
      const index = existingExpandedArray.indexOf(scheduleId)

      if (index !== -1) {
        // Se o scheduleId já existir, remova-o do array
        existingExpandedArray.splice(index, 1)
      } else {
        // Caso contrário, adicione-o ao array
        existingExpandedArray.push(scheduleId)
      }

      // Atualizar o estado e retornar o novo objeto
      return { ...newExpandedStates, [agendamentoId]: existingExpandedArray }
    })
  }
  const handleMouseDown = (event) => {
    // if (type === CAROUSEL_PROPS.SCROLL) {
    setIsDragging(true)
    setStartX(event.clientX - divRef.current.offsetLeft)
    setScrollLeft(divRef.current.scrollLeft)
    divRef.current.style.scrollBehavior = 'unset'
    // }
  }

  const handleMouseMove = (event) => {
    // if (type === CAROUSEL_PROPS.SCROLL) {
    if (!isDragging) return
    const x = event.clientX - divRef.current.offsetLeft
    const scrollOffset = (x - startX) * 1.0 // Ajuste a velocidade de rolagem conforme necessário
    divRef.current.scrollLeft = scrollLeft - scrollOffset
    // }
  }

  const handleMouseUp = () => {
    // if (type === CAROUSEL_PROPS.SCROLL) {
    setIsDragging(false)
    divRef.current.style.scrollBehavior = 'smooth'
    // }
  }

  const renderImg = (client) => {
    let img = ''

    if (client?.businessClientPhotoUrl) {
      img = (
        <img
          src={client?.businessClientPhotoUrl}
          alt="Logo"
          style={{ height: '46px' }}
        />
      )
    }

    if (client.businessClientId === 'undefined') {
      img = <img src={logoPostei} alt="Logo" style={{ height: '46px' }} />
    } else if (!client?.businessClientPhotoUrl) {
      img = <img src={companyIcon} alt="Logo" style={{ height: '46px' }} />
    }
    return img
  }

  function truncateText(text) {
    const maxLength = 150
    return text?.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text
  }

  const divRef = useRef(null)
  return (
    <motion.div
      // key={expanded}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{ width: '100%' }}
    >
      <div className={styles.page}>
        <div className={styles.container_content_scheduling_calendar}>
          <div className={styles.title_scheduling}>
            <p style={{ color: '#898989', fontSize: '12px' }}>Calendário</p>
            <p style={{ color: '#3E3E3E', fontSize: '22px' }}>Agendamentos</p>
          </div>

          <Calendar
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            scheduledDays={scheduledDays}
            dateSchedule={dateSchedule}
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
          />
        </div>
        <div
          ref={divRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          className={styles.container_content_scheduling_grid}
        >
          <table style={{ display: 'flex', flex: 1 }}>
            {newOrderknowLedgeChannel?.map((client) => (
              <>
                <div
                  className={styles.container_table_th}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <th className={styles.table_th} key={client.businessClientId}>
                    <div className={styles.table_header}>
                      <div
                        onClick={() =>
                          hadleUpdateFavorite(client.businessClientId)
                        }
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        {client.favorite ? (
                          <FavoriteStarsPress />
                        ) : (
                          <FavoriteStars />
                        )}
                      </div>
                    </div>
                    <div className={styles.table_body}>{renderImg(client)}</div>
                    <div className={styles.table_footer}>
                      <p
                        style={{
                          fontSize: '12px',
                          fontWeight: '300',
                          color: '#898989',
                        }}
                      >
                        {client.businessName}
                      </p>
                    </div>
                  </th>

                  <tbody className={styles.table_tbody}>
                    {client.data.map((schedule, indexSchedule) => (
                      <td
                        key={`${schedule?.publicationId}-${indexSchedule}`}
                        className={
                          expandedStates[client.businessClientId] &&
                          expandedStates[client.businessClientId].includes(
                            schedule?.publicationId
                          )
                            ? styles.scheduling_area_expanded
                            : styles.scheduling_area
                        }
                        onClick={() =>
                          handleExpandArea(
                            client.businessClientId,
                            schedule?.publicationId
                          )
                        }
                      >
                        <div>
                          {schedule?.publicationGenerateArtJson?.params
                            ?.length === 0 && (
                            <p
                              style={{
                                fontSize: '16px',
                                fontWeight: '500',
                                color: '#190027',
                              }}
                            >
                              Publicação propria:
                            </p>
                          )}
                          <p
                            style={{
                              fontSize: '16px',
                              fontWeight: '500',
                              color: '#190027',
                            }}
                          >
                            {expandedStates[client.businessClientId] &&
                            expandedStates[client.businessClientId].includes(
                              schedule?.publicationId
                            )
                              ? schedule?.publicationHeadline
                              : truncateTitle(
                                  schedule?.publicationHeadline,
                                  35
                                )}
                          </p>
                          <p
                            className={`${styles.scheduling_area_content} ${
                              expandedStates[client.businessClientId] &&
                              expandedStates[client.businessClientId].includes(
                                schedule?.publicationId
                              )
                                ? styles.expanded
                                : styles.invisible
                            } `}
                          >
                            {truncateText(schedule?.publicationLegend)}
                          </p>
                        </div>

                        <div className={styles.scheduling_area_footer}>
                          <p
                            style={{
                              fontSize: '12px',
                              fontWeight: '600',

                              color:
                                expandedStates[client.businessClientId] &&
                                expandedStates[
                                  client.businessClientId
                                ].includes(schedule?.publicationId)
                                  ? '#FF8B00'
                                  : '#898989',
                            }}
                          >
                            {renderHours(schedule?.publicationDateDay)}
                          </p>
                          <div
                            className={styles.icon_eye}
                            onClick={() =>
                              navigation('/SchedulePublicationPage', {
                                state: {
                                  date: formatDate(
                                    schedule?.publicationDateDay
                                  ),
                                  index: indexSchedule,
                                },
                              })
                            }
                          >
                            <Eye />
                          </div>
                        </div>
                      </td>
                    ))}
                  </tbody>
                </div>
              </>
            ))}
          </table>
        </div>
      </div>
    </motion.div>
  )
}

export default SchedulingPage
