import { useState, useEffect, useRef } from 'react'
import { ListTable } from '../../components/ListTable'
import { Row } from '../../components/ListTable/Row'
import { TableItem } from '../../components/ListTable/TableItem'

import { EditPencil, Trash } from '../../assets/svgs/icons'
import styles from './styles.module.css'
import { HistoryModal, SimpleEditionModal } from '../../components'
import SimpleEditionModalTemplate from '../../components/SimpleEditionModalTemplate'

import {
  PublicationService,
  SubscriptionService,
  TimelineActionsService,
} from '../../services'
import { Loading } from '../../components/Loading'
import { useNavigate } from 'react-router-dom'
import {
  notifyErrorCustom,
  notifySuccessCustom,
  notifyWarningCustom,
} from '../../utils/notify'

import { useClient } from '../../hooks/useClient'

export const HistoryPage = () => {
  const { clientData } = useClient()

  const [isSimplesEditModalOpen, setIsSimplesEditModalOpen] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const [imageUrl, setImageUrl] = useState(undefined)
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const navigation = useNavigate()

  // const [productTargetAudienceOptions, setProductTargetAudienceOptions] =
  //   useState([])
  // const [productTypeOptions, setProductTypeOptions] = useState([])

  const [fontToRender, setFontToRender] = useState('')
  const [imageLayers, setImageLayers] = useState([])

  const [publications, setPublications] = useState([])

  const [loading, setLoading] = useState(true)

  const [simplesEditModalData, setSimplesEditModalData] = useState({})

  const navigate = useNavigate()

  const [headlineText, setHeadlineText] = useState('') //simplesEditModalData?.headline
  const [headlineSubtitle, setHeadlineSubtitle] = useState('') //simplesEditModalData?.legend

  const headers = [
    // 'Código',
    'Publicação',
    'Campanha',
    '  Data de Criação',
    '  Data da Publicação',
    '  Ações',
  ]

  useEffect(() => {
    setLoading(true)
    loadData()

    setHeadlineText(simplesEditModalData?.headline ?? '')
    setHeadlineSubtitle(simplesEditModalData?.legend ?? '')

    loadFontImages()
  }, [clientData])

  const loadData = async () => {
    if (clientData?.uuid === undefined) {
      const { data, responseStatus } =
        await PublicationService.getAllPublicationByLoginByNotBusinessClient()

      if (responseStatus === 200) {
        setPublications(data)
      }

      setLoading(false)
      return
    }

    const { data, responseStatus } =
      await PublicationService.getAllPublicationByLoginByBusinessClientId(
        clientData?.uuid
      )

    if (responseStatus === 200) {
      setPublications(data)
    }

    setLoading(false)
  }

  const loadFontImages = async () => {
    // const { data } = await BrandingService.getAllBrandingByBusiness()
    // const fontData = data.at(0).fontsBase64.fontTitleBase64
    // const fontName = fontData.name.replace(/\..+$/, '')
    // const dynamicFont = new FontFace(
    //   fontName,
    //   `url(data:font/woff2;base64,${fontData.data})`
    // )
    // await dynamicFont.load().then((font) => document.fonts.add(font))
    // setFontToRender(fontName)
  }

  // const handleOpenSimpleEditionModal = (data) => {
  //   console.log('dataHistorico', data)
  //   setSimplesEditModalData(data)
  //   setIsSimplesEditModalOpen(true)
  // }

  const handleOpenSimpleEditionModal = async (data) => {
    const publicationCount = await PublicationService.getAllPublicationCount()
    const schedulingCount =
      await TimelineActionsService.getAllTimelineCountPublication()
    const userSubscription = await SubscriptionService.getSubscriptionByUser()

    if (
      userSubscription.data.length <= 0 &&
      (publicationCount.data.count >= 8 || schedulingCount.data.count >= 60)
    ) {
      notifyWarningCustom(
        'Você atingiu o limite para realizar reagendamentos, consulte nossos planos para mais informações!'
      )
      return
    } else if (userSubscription.data.length > 0) {
      const indexSchedulling =
        await userSubscription.data[0].Plan.PlanItems.findIndex(
          (item) => item.type === 'scheduling_quantity'
        )
      const quantityItemSchedulling =
        userSubscription.data[0].Plan.PlanItems[indexSchedulling].quantity

      if (
        schedulingCount.data.count >= quantityItemSchedulling &&
        quantityItemSchedulling != null
      ) {
        notifyWarningCustom(
          'Você atingiu o limite para realizar reagendamentos, consulte nossos planos para mais informações!'
        )
        return
      }
    }
    const publication = await PublicationService.getPublication(data.uuid)
    setImageLayers(publication.data[0])
    // setSimplesEditModalData(publication.data[0].generateArtJson)
    // setSimplesEditModalImageData(data.images[0])
    setIsSimplesEditModalOpen(true)
  }

  const handleCloseSimpleEditionModal = () => {
    setIsSimplesEditModalOpen(false)
  }

  const handleNavigate = () => {
    navigate('/NewPostFormPage', {
      state: { repost: true, dataPublication: simplesEditModalData },
    })
  }

  const handleDeletePost = async (id) => {
    setLoading(true)

    const publicationResponse = await PublicationService.deletePublication(id)

    if (
      publicationResponse?.responseStatus === 200 ||
      publicationResponse?.responseStatus === 204
    ) {
      notifySuccessCustom('Rascunho removido com sucesso!')
    } else {
      notifyErrorCustom('Erro ao remover rascunho!')
    }
    setLoading(false)
    loadData()
  }

  function formatDate(data) {
    data = new Date(data)

    let dia = data.getDate()
    let mes = data.getMonth() + 1 // getMonth() retorna mês de 0-11
    let ano = data.getFullYear()

    dia = dia < 10 ? '0' + dia : dia
    mes = mes < 10 ? '0' + mes : mes

    return dia + '/' + mes + '/' + ano
  }

  const VideoFirstFrame = ({ videoUrl }) => {
    const videoRef = useRef(null)
    const canvasRef = useRef(null)
    const [imageSrc, setImageSrc] = useState('')

    useEffect(() => {
      const video = videoRef.current
      const canvas = canvasRef.current
      const context = canvas.getContext('2d')

      const captureFrame = () => {
        // Desenha o primeiro frame no canvas assim que o vídeo estiver carregado
        context.drawImage(video, 0, 0, canvas.width, canvas.height)

        // Converte o conteúdo do canvas para uma imagem em base64
        const dataUrl = canvas.toDataURL('image/jpeg')
        setImageSrc(dataUrl)
      }

      if (video) {
        video.addEventListener('loadeddata', () => {
          video.currentTime = 0.1 // Pulando para 0.1 segundos para garantir que um frame seja capturado
        })

        video.addEventListener('seeked', captureFrame)

        // Limpeza dos eventos quando o componente desmonta
        return () => {
          video.removeEventListener('loadeddata', captureFrame)
          video.removeEventListener('seeked', captureFrame)
        }
      }
    }, [videoUrl])

    return (
      <div>
        <video ref={videoRef} src={videoUrl} style={{ display: 'none' }} />
        <canvas
          ref={canvasRef}
          width={640} // Largura desejada
          height={360} // Altura desejada
          style={{ display: 'none' }}
        />
        {imageSrc && <img src={imageSrc} alt="First frame" />}
      </div>
    )
  }

  return (
    <>
      <Loading enable={loading} />

      <HistoryModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        modalData={modalData}
        setModalData={setModalData}
        scheduling={true}
      />

      <ListTable
        headers={headers}
        label="Historico"
        title="Historico de Publicações"
        disabledButton={true}
      >
        {publications.map((publication, index) => (
          <Row key={index}>
            <TableItem style={{}}>
              <div
                className={`${styles.table_item_gap} ${styles.secondColumn}`}
              >
                <div>
                  {!publication?.publicationMovieUrl && (
                    <img src={publication?.publicationImageUrl} alt="" />
                  )}
                  {/* {publication?.publicationMovieUrl && (
                    <img src={publication?.publicationMovieUrl} alt="" />
                  )} */}

                  {/* {publication?.publicationMovieUrl && (
                    <VideoFirstFrame
                      videoUrl={publication?.publicationMovieUrl}
                    />
                  )} */}
                </div>

                <p className={styles.textLineRender}>{publication?.headline}</p>
              </div>
            </TableItem>

            <TableItem>
              <p className={styles.textLineRender}>
                {publication?.Campaign?.name}
              </p>
            </TableItem>
            <TableItem>
              <span className={styles.bold}>
                {formatDate(publication?.createdAt)}
              </span>
            </TableItem>
            <TableItem>
              <span className={styles.bold}>
                {formatDate(publication?.publicationDate)}
              </span>
            </TableItem>
            <TableItem style={{ maxWidth: 170 }}>
              <div className={styles.last_column}>
                <div className={styles.icons_container}>
                  {!publication?.publicationMovieUrl && (
                    <div
                      onClick={() => handleOpenSimpleEditionModal(publication)}
                      className={styles.icon}
                    >
                      <EditPencil color={'#0F172A'} />
                    </div>
                  )}

                  {publication?.publicationMovieUrl && (
                    <div
                      className={styles.icon}
                      style={{ cursor: 'auto' }}
                    ></div>
                  )}

                  <div
                    className={styles.icon}
                    onClick={() => {
                      if (publication?.publicationMovieUrl) {
                        return
                      }

                      handleDeletePost(publication?.uuid)
                    }}
                  >
                    <Trash color={'#000000'} />
                  </div>
                </div>
              </div>
            </TableItem>
          </Row>
        ))}

        <SimpleEditionModalTemplate
          isOpen={isSimplesEditModalOpen}
          setNextStep={(teste1, teste2) => {
            navigation('/NewPublicationPage', {
              state: {
                edit: true,
                dataPublication: {
                  ...imageLayers,
                  generateArtJson: {
                    ...teste2?.tempImageLayers,
                    srcComTratativa: teste1.replace(
                      /^data:image\/png;base64,/,
                      ''
                    ),
                  },
                },
              },
            })
          }}
          imageUrl={imageUrl}
          setImageUrl={setImageUrl}
          imageLayers={imageLayers?.generateArtJson}
          setImageLayers={setImageLayers}
          onClose={handleCloseSimpleEditionModal}
          title={headlineText}
          subtitle={headlineSubtitle}
          publicTargetOptions={[]}
          productOptions={[]}
          // onChangeColorPalleteSubtittle={(e) => {}}
          //onChangeColorPalleteTittle={(e) => {}}
          onChangeColorPalleteTittle={(e) => setTitle(e.target.value)}
          onChangeColorPalleteSubtittle={(e) => setSubTitle(e.target.value)}
        />
      </ListTable>
    </>
  )
}
