import styles from './styles.module.css'
import { notifyErrorCustom } from '../../../utils/notify'
import { UserService } from '../../../services'
import { motion } from 'framer-motion'
import { PreviousPass } from '../../../components/PreviousPass'

function StepEmail({ setEmail, email, setStep }) {
  const STEPS = {
    STEP_PASS_NAME: 'stepName',
    STEP_PASS_PHONE: 'stepPhone',
  }

  const stepsArray = [
    {
      pass: '1º Passo',
      title: 'Nome e Sobrenome',
      current: true
    },
    {
      pass: '2º Passo',
      title: 'Email',
      current: true
    },
    {
      pass: '3º Passo',
      title: 'Telefone',
      current: false
    },
    {
      pass: '4º Passo',
      title: 'Senha',
      current: false
    },
    {
      pass: '5º Passo',
      title: 'CPF',
      current: false
    },
    {
      pass: '6º Passo',
      title: 'Endereço',
      current: false
    },
  ];

  const isValidEmail = (email) => {
    // Expressão regular para validar e-mail
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    // Testa o e-mail recebido com o regex
    return emailRegex.test(email)
  }

  const verifyEmailExist = async (email) => {
    let response = false
    const checkEmailExists = await UserService.getUserCheckEmailExists(email)
    if (checkEmailExists?.data?.exists) {
      response = checkEmailExists?.data?.exists
    } else {
      response = checkEmailExists?.data?.exists
    }
    return response
  }

  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (email === '') {
        notifyErrorCustom('Preencha o campo com seu email')
        return
      } else if (!isValidEmail(email)) {
        notifyErrorCustom('Esse não é um formato de email valido')
        return
      }

      const checkEmailExists = await UserService.getUserCheckEmailExists(email)
      if (checkEmailExists?.data?.exists === true) {
        
        notifyErrorCustom('E-mail já cadastrado!')
        return
        // response = checkEmailExists?.data?.exists
      }

      setStep(STEPS.STEP_PASS_PHONE)
    } else {
      setStep(STEPS.STEP_PASS_NAME)
    }
  }
  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        <div>
          {/* <PreviousPass steps={stepsArray}/> */}
        </div>
        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>Cadastro</p>
          <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>Criar conta</h1>

          <div className={styles.box_input_email}>
            <p>Agora nos informe seu e-mail</p>
            <input
              className={styles.input_email}
              type="text"
              value={email}
              placeholder="E-mail"
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className={styles.box_button}>
          <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
            Voltar
          </button>
          <button
            className={styles.next_button}
            onClick={() => handleNavigate('next')}
          >
            Avançar
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepEmail
