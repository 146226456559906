import { Navigate, useNavigate } from 'react-router-dom'
import styles from './styles.module.css'
import { motion } from 'framer-motion'
import { notifyErrorCustom } from '../../../utils/notify'
import { useEffect } from 'react'
import { UserAccountsService } from '../../../services'
import { PreviousPass } from '../../../components/PreviousPass'

function StepName({ setName, name, setStep }) {
  const STEPS = {
    STEP_PASS_EMAIL: 'stepEmail',
    STEP_PASS_IMAGE: 'stepImage',
  }

  const stepsArray = [
    {
      pass: '1º Passo',
      title: 'Nome e Sobrenome',
      current: true
    },
    {
      pass: '2º Passo',
      title: 'Email',
      current: false
    },
    {
      pass: '3º Passo',
      title: 'Telefone',
      current: false
    },
    {
      pass: '4º Passo',
      title: 'Senha',
      current: false
    },
    {
      pass: '5º Passo',
      title: 'CPF',
      current: false
    },
    {
      pass: '6º Passo',
      title: 'Endereço',
      current: false
    },
  ];
  const navigate = useNavigate()

 
  const handleNavigate = async (type) => {
    if (type === 'next') {
      if (name === '') {
        notifyErrorCustom('Preencha o campo com seu nome e sobrenome')
        return
      }
      // setStep(STEPS.STEP_PASS_IMAGE)
      setStep(STEPS.STEP_PASS_EMAIL)
    }else{
      navigate('/signIn', )
    }
  }
  return (
    <motion.div
      initial={{ x: '100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ type: 'tween', duration: 0.5 }}
      style={{
        width: '100%',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={styles.box_step}>
        <div>
          {/* <PreviousPass steps={stepsArray}/> */}
        </div>
        <div className={styles.box_page}>
          <p style={{ margin: '0px' }}>Cadastro</p>
          <h1  style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>Criar conta</h1>

          <div className={styles.box_input_name}>
            <p>Digite seu nome e sobrenome</p>
            <input
              className={styles.input_name}
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              type="text"
              placeholder="Nome"
            />
          </div>
        </div>
        <div className={styles.box_button}>
          <button
            className={styles.back_button}
            onClick={() => handleNavigate('back')}
          >
            Voltar
          </button>
          <button
            className={styles.next_button}
            onClick={() => handleNavigate('next')}
          >
            Avançar
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default StepName
