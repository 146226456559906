import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import InputIA from '../../../components/InputIA'
import { SelectRound } from '../../../components/Select/SelectRound'
import { BrandingService, BucketUploadService, FontService } from '../../../services'
import { motion } from 'framer-motion'
import WebFont from 'webfontloader'
import Loading from '../../../components/Loading'
import { notifyErrorCustom } from '../../../utils/notify'
import { PreviousPass } from '../../../components/PreviousPass'
import Lottie from 'lottie-react'
import lazyLoadingAnimation from '../../../assets/lottie/lazy-loading-animation.json'
import { fetchGoogleFonts } from '../../../services/googleFontsService'




const STEPS = {
  STEP_PASS_COLOR_PALETTE: 'StepColorPalette',
  STEP_PASS_BPS: 'stepBPS',

}

function StepTypography({ setStep, businessAccountData, font, setFont }) {
  const [textIA, setTextIA] = useState('')
  const [textExemple, setTextExemple] = useState('')
  const [fontsOptions, setFontsOptions] = useState([])

  const [branding, setBranding] = useState()

  const [filesSelectedArray, setFilesSelectedArray] = useState([])

  const [loading, setLoading] = useState(false)

  const [googleFonts, setGoogleFonts] = useState([])
  const [fonts, setFonts] = useState([]); // State para guardar as fontes
  const [fontUrls, setFontUrls] = useState(''); // State para guardar as fontes
  const [serif, setSerif] = useState('');


  const stepsArray = [
    {
      pass: '1º Passo',
      title: 'Nicho e Objetivo',
      current: true
    },
    {
      pass: '2º Passo',
      title: 'Paleta de cores',
      current: true
    },
    {
      pass: '3º Passo',
      title: 'Fontes',
      current: true
    },
    {
      pass: '4º Passo',
      title: 'BPS',
      current: false
    },
    {
      pass: '5º Passo',
      title: 'Tom de Voz',
      current: false
    },
    {
      pass: '6º Passo',
      title: 'Objetivo',
      current: false
    },
    {
      pass: '7º Passo',
      title: 'Logo',
      current: false
    },
    {
      pass: '8º Passo',
      title: 'Imagem',
      current: false
    },
    {
      pass: '9º Passo',
      title: 'Geração de Arte',
      current: false
    },
    {
      pass: '10º Passo',
      title: 'Download',
      current: false
    },
  ];

  const getFonts = async () => {
    setLoading(true)
    const fontsData = await fetchGoogleFonts(); // Chama a função do serviço
    
    let fontUrls = ''; // Variável para concatenar os URLs das fontes

    // Mapeia os dados e gera a string concatenada
    const mappedFonts = fontsData.map(element => {
      const fontUrl = element?.files?.italic || ''; // Garante que não seja undefined
      const fontName = element?.family || ''; // Garante que não seja undefined
      
      if (fontUrl) {
        fontUrls += `
         @font-face {
          font-family: '${fontName}';
          src: local('${fontName}'), url('${fontUrl}') format('truetype');
        } `; // Concatena a URL com um " " no final
      }

      return {
        family: element?.family,
        font: fontUrl, // Mantém a URL da fonte no estado
      };
    });

    setFontsOptions(
      fontsData.map((e) => ({ label: e.family, value: e.family, category: e.category }))
    )

    // Remove o último ";" se necessário
    fontUrls = fontUrls.slice(0, -1);
    console.log('fontsData',fontsData)

    // Atualiza os estados
    setGoogleFonts(mappedFonts); // Atualiza o estado com o array de fontes
    setFontUrls(fontUrls); // Atualiza o estado com a string de URLs
    setLoading(false)
  };

  const handleFonts = async () =>{
    const {data} = await fetchGoogleFonts()

    console.log('returnFonts',data)

    data.forEach(element => {
      setGoogleFonts(prevFonts => [
        ...prevFonts,   // Mantém as fontes anteriores
        { 
          family: element.family,
          //font: element?.files?.regular,
         } // Adiciona a nova fonte
      ]);
    });
  }
  console.log('fontsOptions',fontsOptions)

  const handleNavigate = async (type) => {
    if (type === 'next') {

      if(font === ''){
        notifyErrorCustom('Escolha uma fonte para sua arte')
        return
      }
      //
      saveFont()
      setStep(STEPS.STEP_PASS_BPS)
    } else {
      setStep(STEPS.STEP_PASS_COLOR_PALETTE)
    }
  }

 

  useEffect( () => {
    // if (dataToCompare)
    
    // loadFonts()
    getFonts(); // Executa a função ao carregar o componente

    loadBrandin()
    //setFont(fontData.data)
  }, [])

  console.log('textIA', textIA)

  const loadBrandin = async () =>{
    const { data } = await BrandingService.getAllBrandingByBusinessId(businessAccountData.data.uuid)
    setBranding(data[0])
  }

  const [selectedFontIndex, setSelectedFontIndex] = useState(null); // Estado para armazenar o índice da fonte selecionada

  const handleFontClick = (index, font) => {
    setFont(font?.value)
    setSelectedFontIndex(index); // Atualiza o índice da fonte selecionada
  };

  const saveFont = async () =>{
    const {data: dataFont} = await BucketUploadService.getGoogleFontsBucketByName(
      filesSelectedArray[0].name
    )

    const fontsToUpdate =   await formatFonts(filesSelectedArray)

    
    const brandinId = branding.uuid
       
      
     delete branding.uuid
     delete branding.businessId
     delete branding.createdAt
     delete branding.updatedAt
     delete branding.deletedAt
     delete branding.fontsBase64


    const dataToSubmit = {
      ...branding,
      ...fontsToUpdate
    }


    const { responseStatus } = await BrandingService.putBranding(
      brandinId,
      dataToSubmit
   
    )
    

  }

  const formatFonts = async (fonts) => {
    let fontsUploadObj = {}

    fontsUploadObj['fontSubtitleId'] = ''
    fontsUploadObj['fontSubtitleByUploaded'] = false
    fontsUploadObj['fontTitleId'] = ''
    fontsUploadObj['fontTitleByUploaded'] = false
    fontsUploadObj['fontTextId'] = ''
    fontsUploadObj['fontTextByUploaded'] = false

    for (const fonte of fonts) {
      const objToGetId = {
        name: fonte.name,
        data: fonte.fileDataBase64,
      }
      const { data } = await FontService.postExternalFontsGoogle(objToGetId)

      // if (fonte.selectedFontType?.value == 2) {
        fontsUploadObj['fontSubtitleId'] = data.uuid
        fontsUploadObj['fontSubtitleByUploaded'] = false
      // }

      // if (fonte.selectedFontType?.value == 1) {
        fontsUploadObj['fontTitleId'] = data.uuid
        fontsUploadObj['fontTitleByUploaded'] = false
      // }

      // if (fonte.selectedFontType?.value == 3) {
        fontsUploadObj['fontTextId'] = data.uuid
        fontsUploadObj['fontTextByUploaded'] = false
      // }
    }

    return fontsUploadObj
  }

  const handleChangeFilterParams = (selectedFilters) => {

    console.log('263', selectedFilters )
    setSerif(selectedFilters.category)

  }

  // const loadFonts = async () => {
  //   setLoading(true)
  //   let googleFontsBucket = await BucketUploadService.getGoogleFontsBucket()

  //   setFontsOptions(
  //     googleFontsBucket.data.map((e) => ({ label: e.name, value: e.name }))
  //   )
  //   setLoading(false)
  // }

  


  const handleClickSetFont = (index, font) => {
console.log('cliquei na fonte ', font)
    setFont(font?.value)

    const fontArrray = []

    const dataArray = {
      fileDataBase64: font.value,
      isSystemFont: true,
      name: font.value,
      selectedFontType :{
        label : 'Título',
        value : 1
      },
    }

    fontArrray.push(dataArray)

    setSelectedFontIndex(index)
    // const aux = fontArrray
    // //if (aux.length >= 3 || !font) return
    // aux.push({ ...font })

    // const fontFamilies = aux
    //   // .filter((font) => !font.isSystemFont)
    //   .map((font) => font.name)

    // if (fontFamilies.length > 0) {
    //   WebFont.load({
    //     google: {
    //       families: fontFamilies,
    //     },
    //   })
    // }
    setFilesSelectedArray(fontArrray)

    //renderSelectedFontOnDocument()

    // const filteredTypeOptions = fontTypeOptions.filter(
    //   (e) => e.value != selectedFontType.value
    // )
    // setFontTypeOptions(filteredTypeOptions)

    // setSelectedFont(undefined)
    // setSelectedFontType(undefined)
    // setSelectedFontItem(undefined)
    // setFontUpload(false)
  }
  return (
    <>  
      <style>
        {`${fontUrls}`}
      </style>
      {/* <Loading enable={loading} /> */}
      <motion.div
        initial={{ x: '100vw' }}
        animate={{ x: 0 }}
        exit={{ x: '-100vw' }}
        transition={{ type: 'tween', duration: 0.5 }}
        style={{
          width: '100%',
          // height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className={styles.box_step}>
          {/* <PreviousPass steps={stepsArray}/> */}
          {/* <style>
            {importFont}
          </style> */}
          <div className={styles.box_page}>
            <p style={{ margin: '0px' }}>Tipografia</p>
            <h1 style={{ marginTop: '0px', fontSize: '45px', fontWeight: 500 }}>Fonte de Texto</h1>

            <div className={styles.box_content_page}>
              <div className={styles.box_input_search}>
                <InputIA
                  placeholder={'Digite aqui o que deseja'}
                  text={textIA}
                  setText={setTextIA}
                  setLoading={() => {}}
                  clickFunction={() => {}}
                />
              </div>

              <div className={styles.box_selects}>
                <input
                  value={textExemple}
                  onChange={(e) => setTextExemple(e.target.value)}
                  className={styles.input_label}
                  type="text"
                  placeholder="Insira seu texto "
                />
                <SelectRound
                  htmlFor="select"
                  placeholder={serif ? serif : 'Categoria'}
                  defaultSelected={{ label: 'Categoria', value: '' }}
                  options={[
                    { label: 'Todos', value: '' },
                    { label: 'Sans Serif', value: 'sans-serif' },
                    { label: 'Serifa', value: 'serif' },
                  ]}
                  onSelectItem={(selected) => {
                    handleChangeFilterParams({ category: selected.value })
                  }}
                />
              </div>

              <div 
                className={styles.container_box_font}>
                {loading && (
                  <div className={styles.box_example_image}>
                    <Lottie
                      animationData={lazyLoadingAnimation}
                      style={{ width: '100%', height: 100 }}
                      loop={true}
                      autoplay={true}
                    />
                  </div>
                )} 
                {fontsOptions.filter(font => font.label.includes(textIA))
                  .filter(font => font.category.includes(serif))
                  .map((font, index) => (
                    <div 
                    // className={styles.box_font} key={index}
                      className={
                        index === selectedFontIndex
                          ? styles.box_font_select // Adiciona uma classe extra se for a fonte selecionada
                          : styles.box_font
                      }
                      key={index}
                      onClick={() => handleClickSetFont(index, font)} // Atualiza o estado com o índice da fonte clicada
                    >
                  
                      <p className={ index === selectedFontIndex ? styles.font_title_text_selected: styles.font_title_text  }>{font.label}</p>

                      <div className={  index === selectedFontIndex ? styles.box_font_select_text :styles.box_font_text }>
                        <h1
                          className={styles.font_text}
                          //href="https://fonts.gstatic.com/s/abeezee/v22/esDR31xSG-6AGleN6tKukbcHCpE.ttf"
                          style={{ fontFamily: `${font.value}` }}
                        >
                          {textExemple ||
                            'Whereas disregard and contempt for human rights have resulted. Lorem Ipsum dolor it damet setinels'}
                        </h1>
                      </div>
                    </div>
                  )
                  )}
              </div>
            </div>
          </div>
          <div className={styles.box_button}>
            <button
              className={styles.back_button}
              onClick={() => handleNavigate('back')}
            >
              Voltar
            </button>
            <button
              className={styles.next_button}
              onClick={() => handleNavigate('next')}
            >
              Avançar
            </button>
          </div>
        </div>
      </motion.div>
    </>
  )
}

export default StepTypography
